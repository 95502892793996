<template>
    <div>
      <div class="hidden lg:flex" :class="{ 'increase': showSideBar, 'decrease': !showSideBar }"></div> <!-- балластная поползуха -->

        <Transition name="sidebar" @after-enter="buttonClose" @before-leave="buttonMenu">
            <div v-if="!showSideBar" 
                class="fixed flex flex-row justify-content-center h-7rem w-2rem lg:w-2rem bg-primary z-1 cursor-pointer button-bar opacity-90" 
                @click="store.commit('SET_SHOW_SIDEBAR', true)"
            >
                <div class="flex flex-column justify-content-center gap-3">
                    <i class="pi pi-user"></i>
                    <i class="pi pi-bars"></i>
                </div>
            </div>
        </Transition>

        <Transition name="sidebar" @after-enter="buttonClose" @before-leave="buttonMenu">

            <div v-show="showSideBar" class="fixed flex-column surface-100 h-screen w-18rem select-none left-0 top-0 mt-7 z-2 lg:z-1">
                <div class="fixed z-3" style="margin-top: 1.5rem; margin-left: 14.5rem;">
                    <ButtonItem icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" @click="store.commit('SET_SHOW_SIDEBAR', false)"></ButtonItem>
                </div>

                <ScrollPanel
                style="width: 100%; height: 100%"
                :pt="{
                    wrapper: {
                        style: { 'border-right': '2px solid var(--surface-ground)' }
                    },
                    bary: {
                        class: 'hover:bg-primary-400 bg-primary-300 opacity-100 wp-1',
                    }
                }"
                >

                <div class="p-3 flex align-items-center justify-content-center">
                    <!-- <img src="/images/blocks/logos/hyper-700.svg" alt="Image" height="40" /> -->
                </div>
                <div class="overflow-y-auto">
                    <ul class="list-none p-2 m-0">
                        
                        <li>
                        <div class="p-3 text-500 font-medium text-base title">Мои объекты</div> 
                        </li>

                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"
                                v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                                <i class="pi pi-home mr-2"></i>
                                <span class="font-medium">Триумфальная 1</span>
                                <div class="ml-auto">
                                    <span class="inline-flex align-items-center justify-content-center bg-primary font-semibold text-xs border-circle" style="min-width: 1.5rem; height: 1.5rem">2</span>
                                    <i class="pi pi-chevron-down ml-2"></i>
                                </div>
                            </a> 
                            
                            <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">

                                <li>
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-home mr-2"></i>
                                        <span class="font-medium">Объект</span>
                                    </a>
                                </li>                    
                            
                                <li>
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-briefcase mr-2"></i>
                                        <span class="font-medium">Документы</span>
                                    </a>
                                </li>
                            
                                <li>
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-calculator mr-2"></i>
                                        <span class="font-medium">Финансы</span>
                                    </a>
                                </li>

                                <li>
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"
                                        v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                                        <i class="pi pi-comments mr-2"></i>
                                        <span class="font-medium">Чаты</span>
                                        <div class="ml-auto">
                                            <span class="inline-flex align-items-center justify-content-center ml-auto bg-primary font-semibold text-xs border-circle" style="min-width: 1.5rem; height: 1.5rem">2</span>
                                            <i class="pi pi-chevron-down ml-2"></i>
                                        </div>
                                    </a>
                                    <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                        <li>
                                            <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
                                                <i class="pi pi-comment mr-2"></i>
                                                <span class="font-medium">Архитектор</span>
                                                <span class="inline-flex align-items-center justify-content-center ml-auto bg-primary font-semibold text-xs border-circle" style="min-width: 1.5rem; height: 1.5rem">2</span>
                                            </a>

                                        </li>
                                        <li>
                                            <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
                                                <i class="pi pi-comment mr-2"></i>
                                                <span class="font-medium">Менеджер объекта</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>                    

                            </ul>
                        </li>



                    </ul>



                    <ul class="list-none p-2 m-0 border-top-1 surface-border">
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
                                <i class="pi pi-plus mr-2"></i>
                                <span class="font-medium">Новый объект</span>
                            </a>
                        </li>

                    </ul>
                    



                    <ul class="list-none p-2 m-0 border-top-1 surface-border">
                        <li>
                        <div class="p-3 text-500 font-medium text-base title">Профиль</div> 
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
                                <i class="pi pi-user-edit mr-2"></i>
                                <span class="font-medium">Управление аккаунтом</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple" @click="logOut">
                                <i class="pi pi-sign-out mr-2"></i>
                                <span class="font-medium">Выход</span>
                            </a>
                        </li>
                        <li>
                            <div class="h-5rem"> </div>
                        </li>            

                    </ul>

                </div>

            </ScrollPanel>

            </div>
        </Transition>

    </div>
</template>

<script setup>
    import { computed } from 'vue'
    import { useStore } from "vuex";
    import { logOut } from './functions';   

    const store = useStore();
    const showSideBar = computed(() =>{
        return store.state.showSideBar;
    });    

   
    

/*     const buttonClose = () => {
        console.log('buttonClose');
    }
    
    const buttonMenu = () =>  {
        console.log('buttonMenu');
    } */
   



</script>







<style>
    .wp-1 {
        width:5px;
    }

    .sidebar-enter-active {
        animation: fadeinleft 0.35s ease;
        animation-fill-mode: forwards;
    }
    .sidebar-leave-active {
        animation: fadeoutleft 0.35s ease;
        animation-fill-mode: forwards;
    }

    .button-bar {
        margin-top: 0rem;
        margin-left: 0rem;
    }

    @media (min-width:769px){
        .button-bar {
            margin-left: 0rem;
        }
    }
      

    

    @keyframes myfadeoutleft {
        0% {
            opacity: 0;
            transform: translateX(0%);
            transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
        }
        100% {
            opacity: 1;
            transform: translateX(-100%);
        }
    }    



</style>