<template>
  <div class="w-full">
    <div class="flex flex-wrap">
      <label class="w-full p-1">{{props.label}}</label>
      <input-number class="w-6 no-border-right input-width-fix"
                    locale="ru"
                    :allowEmpty="false"
                    :min="props.min"
                    :max="props.max"
                    v-model="model[0]"
                    inputId="integeronly"
      />
      <input-number class="w-6 text-right-ch-input no-border-left input-width-fix"
                    locale="ru"
                    :allowEmpty="false"
                    :min="props.min"
                    :max="props.max"
                    v-model="model[1]"
                    inputId="integeronly"/>
    </div>
    <slider-item class="-mt-1" :min="props.min" :max="props.max" range v-model="model"
                 :step="props.step"/>
  </div>
</template>

<script setup>
import {register} from 'swiper/element/bundle';
import {defineProps, defineModel, watch} from 'vue'
register();
const props = defineProps({
  min: { type: Number, required: true },
  max: { type: Number, required: true },
  step: { type: Number, required: true },
  label: { type: String, required: true }
});

const model = defineModel();

watch(() => model, ()=>{
  if(model[0] > model[1]){
    const oldVal = model[1];
    model[1] = model[0];
    model[0] = oldVal;
  }
}, { deep: true })
</script>

<style scoped>

</style>