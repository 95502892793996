<template>
  <div style="min-height: 80px;">
    <div class="relative xl:static flex justify-content-between surface-overlay border-bottom-1 surface-border px-5 w-screen left-0" style="min-height: 80px; position: fixed !important;">
      <img @click="router.push({name: 'frontPage'})" src="/images/ci/logo_works_color_hor.svg" alt="AI Home Studio" height="38" class="mr-0 xl:mr-6 align-self-center cursor-pointer"/>
      <a v-ripple class="cursor-pointer block xl:hidden text-700 align-self-center p-ripple"
          v-styleclass="{
                    selector: '@next',
                    enterClass: 'hidden',
                    leaveToClass: 'hidden',
                    hideOnOutsideClick: true
                }"
      >
        <i class="pi pi-bars text-2xl"></i>
      </a>
      <div
          class="flex-grow-1 justify-content-between hidden xl:flex absolute xl:static w-full surface-overlay left-0 top-100 pt-2 pl-5 z-1 shadow-2 xl:shadow-none xl:justify-content-end">
        <ul class="list-none p-0 m-0 flex select-none flex-column xl:flex-row justify-content-end title">
          <li>
            <a v-ripple @click="router.push({name: 'catalog'})"
               class="flex h-full px-6 p-3 xl:px-3 xl:py-2 align-items-center text-600 hover:text-900 border-left-2 xl:border-bottom-2 xl:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
            >
              <i class="pi pi-home mr-2"></i>
              <span>Загородный дом</span>
            </a>
          </li>
          <li>
            <a v-ripple @click="store.commit('SET_SHOW_SIDEBAR', true)"
               class="flex h-full px-6 p-3 xl:px-3 xl:py-2 align-items-center text-600 hover:text-900 border-left-2 xl:border-bottom-2 xl:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
            >
              <i class="pi pi-building mr-2"></i>
              <span>Ремонт квартиры</span>
            </a>
          </li>
          <li>
            <a v-ripple
               class="flex h-full px-6 p-3 xl:px-3 xl:py-2 align-items-center text-600 hover:text-900 border-left-2 xl:border-bottom-2 xl:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
               v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
            >
              <i class="pi pi-info-circle mr-2"></i>
              <span>О нас</span>
              <i class="pi pi-angle-down ml-1"></i>
            </a>
            <!-- начало меню второго уровня -->
            <ul class="z-4 list-none py-3 px-6 m-0 xl:-m-2 xl:px-0 xl:py-0 border-round shadow-0 xl:shadow-2 xl:border-1 border-50 xl:absolute surface-overlay hidden origin-top w-full xl:w-11rem cursor-pointer">
              <li>
                <a v-ripple
                   class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                  <span class="font-medium">Компания</span>
                </a>
              </li>
              <li class="relative">
                <a v-ripple
                   class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                  <span class="font-medium">Пресса о нас</span>
                </a>
              </li>
              <li class="relative">
                <a v-ripple
                   class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                  <span class="font-medium">Сервисы</span>
                </a>
              </li>
              <li class="relative">
                <a v-ripple
                   class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                  <span class="font-medium">Партнёры</span>
                </a>
              </li>
              <li class="relative">
                <a v-ripple
                   class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                  <span class="font-medium">Для инвесторов</span>
                </a>
              </li>
              <li class="relative">
                <a v-ripple
                   class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                  <span class="font-medium">Для прессы</span>
                </a>
              </li>
            </ul>
            <!-- конец меню второго уровня -->
          </li>
          <li>
            <a v-ripple
               class="flex h-full px-6 p-3 xl:px-3 xl:py-2 align-items-center text-600 hover:text-900 border-left-2 xl:border-bottom-2 xl:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
            >
              <i class="pi pi-compass mr-2"></i>
              <span>О стройке</span>
            </a>
          </li>
          <li>
            <a v-ripple
               class="flex h-full px-6 p-3 xl:px-3 xl:py-2 align-items-center text-600 hover:text-900 border-left-2 xl:border-bottom-2 xl:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
            >
              <i class="pi pi-map-marker mr-2"></i>
              <span>Контакты</span>
            </a>
          </li>
          <li>
            <a @click="goProfile"
               v-ripple
               class="flex h-full px-6 p-3 xl:px-3 xl:py-2 align-items-center text-600 hover:text-900 border-left-2 xl:border-bottom-2 xl:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
            >
              <i class="pi pi-user mr-2"></i>
              <span>Личный кабинет</span>
            </a>
          </li>
        </ul>
        <!-- начало правого блока профиля -->
        <ul v-if="isAuth"
            class="list-none p-0 m-0 flex select-none flex-column xl:flex-row border-top-1 surface-border xl:border-top-none">
          <li class="border-top-1 surface-border xl:border-top-none">
            <a @click="logOut"
               v-ripple
               class="flex h-full px-6 p-3 xl:px-3 xl:py-2 align-items-center border-left-2 xl:border-bottom-2 xl:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
            >
              <!--<img src="/images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 xl:mr-0" style="width: 32px; height: 32px" />-->
              <i class="pi pi-sign-out text-base xl:text-xl mr-2 xl:mr-0"></i>
              <span class="block xl:hidden font-medium">Выйти</span>
            </a>
          </li>
        </ul>
        <!-- конец правого блока профиля -->
      </div>
    </div>
  </div>
</template>

<script setup>
//общий
import {computed} from "vue";

import {logOut} from './functions';

import {useStore} from "vuex";
import {useRouter} from 'vue-router';

const store = useStore();

const router = useRouter();

const isAuth = computed(() => {
  return store.state.isAuth;
});

const goProfile = () => {
  if (store.state.isAuth) {
    router.push({name: 'customer'});
    console.log('GOTO HOUSE FORM');
  } else {
    store.commit('SET_SHOW_MODAL', true);
  }
};

//return (goHouseForm, goProfile);

</script>


