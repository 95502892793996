import axios from 'axios';
import store from '../store';

//добавляем тост в шину 
export const addToast = (toast) => {
    store.commit('SET_TOAST', toast);
};

//сохраняем пользователя а БД
export const updateUser = ({id, phone, name, email}) => {
    const senddata = {
        id: id,
        phone: phone,
        name: name,
        email: email
    };
    
    axios
        .post('/register/', senddata)
        .then(response => {
            if (response.data.status === 'ok'){
                addToast({
                    severity:'success', 
                    summary:'Данные сохранены', 
                    detail: '',
                    life: 3000
                });
                store.dispatch('loadUserData');
            }
        })
        .catch(error => {
            console.error('Ошибка:', error);
        });                
};

//отправляем SMS
export const requestSMS = ({phone}) => {
    const senddata = {
        phone: phone,
    };
    axios
        .post('/sendsms/', senddata)
        .then(response => {
            console.log('Ответ статус:', response.data.status);
            console.log('Ответ:', response.data.user_id);
            store.commit('SET_AUTH_STATUS', 'sms');
        })
        .catch(error => {
            console.error('Ошибка:', error);
            //store.commit('SET_AUTH_STATUS', 'sms'); //------------------------------------- поканетуинтернету
        });               
};

//Выход и обнуление состояния
export const logOut = () => {
    addToast({
                severity:'success', 
                summary:'Вы успешно вышли', 
                detail: '',
                life: 1500
            });
    axios.post('/logout/','')
    .then(response => {
        console.log('Ответ:', response.data);
    })
    .catch(error => {
        console.error('Ошибка:', error);
    });
    store.commit('RESET_STATE');  
};

//Имитируем клик мышкой
export const simulateClick = () => {
    const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        clientX: -5,
        clientY: -5
    });
    document.dispatchEvent(clickEvent);
    console.log ('Накликано');
};


/* async function updateUser ({id, phone, name, email}) {
    
    const senddata = {
        id: ${id},
        phone: ${phone},
        name: ${name},
        email: ${email}
    };

  
    return {
        refA,
        computedA,
    };
}
 */