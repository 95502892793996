import { createApp } from 'vue'
import App from './App.vue'
import store from './store'; // Импорт экземпляра хранилища Vuex


//PrimeVue
import 'primevue/resources/themes/lara-light-blue/theme.css'
import PrimeVue from "primevue/config";
import './assets/css/theme.css';
import './assets/app.css';

import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";


import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Chip from 'primevue/chip';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import InputSwitch from 'primevue/inputswitch';
import Slider from 'primevue/slider';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Checkbox from 'primevue/checkbox';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ScrollPanel from 'primevue/scrollpanel';
import MultiSelect from 'primevue/multiselect';
import SidebarPrimevue from 'primevue/sidebar';
import Dialog from 'primevue/dialog';

//import { Swiper, SwiperSlide } from 'swiper/vue';

import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import TreeSelect from 'primevue/treeselect';

import BadgeDirective from 'primevue/badgedirective';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import ProgressSpinner from 'primevue/progressspinner';

//Material simbols
import 'material-symbols'; //не забыть оставить outlined css

const app = createApp(App);
app.use(store);

//добавляем компоненты PrimeVue

app.use(PrimeVue);

app.use(ToastService);
app.config.globalProperties.$toast = ToastService;

/*app.component('SwiperContainer', Swiper);
app.component('SwiperSlide', SwiperSlide);*/
app.component('DialogItem', Dialog);
app.component('SidebarItem', SidebarPrimevue);
app.component('MultiSelect', MultiSelect);
app.component('BadgeItem', Badge);
app.component('ButtonItem', Button);
app.component('ChipItem', Chip);
app.component('InputText', InputText);
app.component('ToastItem', Toast);
app.component('InputMask', InputMask);
app.component('TreeItem', TreeSelect);
app.component('RadioButton', RadioButton);
app.component('SelectButton', SelectButton);
app.component('InputSwitch', InputSwitch);
app.component('SliderItem', Slider);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('CheckboxItem', Checkbox);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('ScrollPanel', ScrollPanel);
app.component('InputNumber', InputNumber);
app.component('ProgressSpinner', ProgressSpinner);

import Tooltip from 'primevue/tooltip';
app.directive('tooltip', Tooltip);


app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

//аxios
import axios from 'axios'
axios.defaults.baseURL = 'https://wpapi.smarthomecrafters.ai';
axios.defaults.withCredentials = true;
app.config.globalProperties.$axios = axios;


//свои компоненты
import mainMenu from './views/view-menu'
app.component('MenuItem', mainMenu);

import Footer from './views/view-footer'
app.component('FooterItem', Footer);

import SideBar from './views/view-sidebar'
app.component('SideBar', SideBar);

import SlideOver from './views/view-slideover'
app.component('SlideOver', SlideOver);

import PageLogin from './views/view-login'
app.component('viewLogin', PageLogin);

import PageSMS from './views/view-sms'
app.component('viewSMS', PageSMS);

import ViewRegister from './views/view-register'
app.component('viewRegister', ViewRegister);

import ViewSwipe from './views/view-swipe'
app.component('ViewSwipe', ViewSwipe);

import ViewHouseCard from './views/view-housecard'
app.component('ViewHouseCard', ViewHouseCard);

import MainPage from './components/mainPage'
app.component('MainPage', MainPage);

import ContainerBlock from './components/containerBlock'
app.component('ContainerBlock', ContainerBlock);

import FaqView from './views/view-faq'
app.component('FaqView', FaqView);

/* import ViewFilterRange from './views/view-filter-range.vue'
app.component('ViewFilterRange', ViewFilterRange); */

//подключаем router
import router from './router';

app.use(router);
app.mount('#app');

axios.interceptors.response.use(
  (response) => {
    return response;
  }, 
  (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch('setAuth', false);
        router.push('/');
      }
  }
);