<template>
  <main-page>

    <container-block class="overflow-auto mb-7 xl:pr-3 pb-3">
      <swiper-container
          :slides-per-view="1"
          :centerInsufficientSlides="true"
          :loop="true"
          :scrollbar="{draggable: true}"
          :autoplay="{delay: 400000000, disableOnInteraction: true}"
          :navigation="true"
          :speed="800"
          @swiperprogress="onProgress"
          @swiperslidechange="onSlideChange"
      >
        <swiper-slide v-for="house in houses" :key="house.name"
                      class="w-full flex align-items-center justify-content-center bg-white pb-3">
          <div
              class="w-full xl:w-9 flex flex-wrap xl:flex-nowrap align-items-center justify-content-center- sm:justify-content-start md:justify-content-center xl:justify-content-start">
            <div
                class="px-4 mt-8 xl:mt-0 mb-8 xl:mb-0 w-full xl:w-8 xl:flex-order-0 flex-order-1 flex flex-column justify-content-center align-items-center xl:align-items-start">
              <div class="w-12 md:w-10 xl:w-10">
                <span class="text-blue-500 font-semibold text-lg mb-2">ОТ ИДЕИ ДО КЛЮЧЕЙ!</span>
                <h2 class="font-bold text-4xl sm:text-4xl mt-0 mb-4 title">
                  Почувствуйте себя дома,<br/> а мы поможем его построить
                </h2>
                <div class="flex gap-3">
                  <ButtonItem type="button" label="Начать" icon="pi pi-arrow-right" iconPos="right"
                              class="z-2"></ButtonItem>
                  <ButtonItem type="button" label="Как мы работаем" class="p-button-outlined z-2"></ButtonItem>
                </div>
              </div>
            </div>
            <div class="flex justify-content-center align-items-center overflow-visible">
              <img src="/images/test/front01.jpg" alt="Image" style=""/>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </container-block>

    <container-block flex in-center class="flex-wrap border-round text-500">
      <div class="w-12 md:w-6 p-2">
        <div
            class="flex justify-content-between align-items-center p-4 border-round surface-border border-1 bg-white hover:border-primary shadow-1 h-7rem">
          <span class="lg:text-lg  font-medium title">Построить дом</span>
          <i class="material-symbols-outlined text-6xl font-light text-primary">home_work</i>
        </div>
      </div>
      <div class="w-12 md:w-6 p-2 cursor-pointer" @click="router.push({name: 'catalog'})">
        <div
            class="flex justify-content-between align-items-center p-4 border-round surface-border border-1 bg-white hover:border-primary shadow-1 h-7rem">
          <span class="lg:text-lg font-medium title">Проекты домов</span>
          <i class="material-symbols-outlined text-6xl font-light text-primary">family_home</i>
        </div>
      </div>
      <div class="w-12 md:w-6 p-2">
        <div
            class="flex justify-content-between align-items-center p-4 border-round surface-border border-1 bg-white hover:border-primary shadow-1 h-7rem">
          <span class="lg:text-lg font-medium title">Индивидуальный проект</span>
          <i class="material-symbols-outlined text-6xl font-light text-primary">location_away</i>
        </div>
      </div>
      <div class="w-12 md:w-6 p-2">
        <div
            class="flex justify-content-between align-items-center p-4 border-round surface-border border-1 bg-white hover:border-primary shadow-1 h-7rem">
          <span class="lg:text-lg font-medium title">Проверить участок</span>
          <i class="material-symbols-outlined text-6xl font-ultralight text-primary">forest</i>
        </div>
      </div>
    </container-block>

    <container-block flex class="mt-7 mb-5 flex-column">
      <container-block in-center>
        <div class="text-3xl title ml-2">Популярные проекты</div>
      </container-block>
      <container-block class="h-30rem mt-4 xl:pr-3">
        <swiper-container
            :slides-per-view="1"
            :centerInsufficientSlides="true"
            :loop="true"
            :zoom="true"
            :scrollbar="{
            draggable: true,
        }"
            :breakpoints="{
        680: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        900: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        1300: {
            slidesPerView: 4,
            spaceBetween: 30
        },
        1600: {
            slidesPerView: 5,
            spaceBetween: 30
        },
        1900: {
            slidesPerView: 6,
            spaceBetween: 30
        },

        }"
            :autoplay="{
            delay: 40000,
            disableOnInteraction: true
        }"
            :navigation="true"
            :speed="800"
            @swiperprogress="onProgress"
            @swiperslidechange="onSlideChange"
        >
          <swiper-slide v-for="house in houses" :key="house.name"
                        class="flex justify-content-center align-items-center">

            <ViewHouseCard
                :name="house.name"
                :area="house.area"
                :material="house.wall_material"
                :bedrooms="house.bedrooms"
                :bathrooms="house.bathrooms"
                :mortgage="house.mortgage"
                :price="house.price"
                :photos="house.photos"
            ></ViewHouseCard>

          </swiper-slide>

        </swiper-container>
      </container-block>
    </container-block>

    <container-block in-center
                     class="flex-wrap surface-card xl:pl-4 px-4 xl:px-0 shadow-2 flex justify-content-between mb-7 mt-8">
      <div class="flex flex-wrap justify-content-around w-full md:w-6">
        <div class="px-5 py-5 flex flex-wrap align-items-center sm:w-full xl:w-6" style="min-width:285px;">
          <i class="w-full pr-4 xl:pr-0 pi pi-map text-4xl text-primary mb-3 font-light"></i>
          <div class="w-full text-900 font-semibold text-4xl mb-3 line-height-3" style="text-wrap: nowrap;">875 участков</div>
          <div class="text-700 m-0" style="text-wrap: nowrap;">проверено перед покупкой</div>
        </div>
        <div class="px-5 py-5 flex flex-wrap align-items-center sm:w-full xl:w-6" style="min-width:285px;">
          <i class="w-full pr-4 xl:pr-0 pi pi-book text-4xl text-primary mb-3"></i>
          <div class="w-full text-900 font-semibold text-4xl mb-3 line-height-3" style="text-wrap: nowrap;">762 проекта</div>
          <div class="text-700 m-0" style="text-wrap: nowrap;">купили на нашем сайте<div/>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-content-around w-full md:w-6">
        <div class="px-5 py-5 flex flex-wrap align-items-center sm:w-full xl:w-6" style="min-width:285px;">
          <i class="w-full pr-4 xl:pr-0 pi pi-briefcase text-4xl text-primary mb-3"></i>
          <div class="w-full text-900 font-semibold text-4xl mb-3 line-height-3" style="text-wrap: nowrap;">372 договора</div>
          <div class="text-700 m-0" style="text-wrap: nowrap;">на проектирование выполнено</div>
        </div>
        <div class="px-5 py-5 flex flex-wrap align-items-center sm:w-full xl:w-6" style="min-width:285px;">
          <i class="w-full pr-4 xl:pr-0 pi pi-home text-4xl text-primary mb-3"></i>
          <div class="w-full text-900 font-semibold text-4xl mb-3 line-height-3" style="text-wrap: nowrap;">519 домов</div>
          <div class="text-700 m-0" style="text-wrap: nowrap;">построено и сдано заказчикам</div>
        </div>
      </div>
    </container-block>

    <container-block flex class="mt-8 py-8 bg-no-repeat bg-cover bg-center"
                     style="background: url(/images/test/03x.jpg);">
      <div class="w-12 md:w-10 lg:w-9 xl:w-9 ">
        <div class="w-11 lg:w-6 p-4 flex flex-column shadow-2 border-round"
             style="background: rgba(255, 255, 255, 0.9);">
          <div class=" text-3xl font-medium title mb-4">Мы сокращаем дистанции</div>
          <p class="text-black-alpha-90 text-lg line-height-3">
            Делаем сложный и долгий процесс выбора, проектирования, строительства и ремонта жилья простым, интересным и
            вдохновляющим! От идеи до ключей!
          </p>
          <a tabindex="0"
             class="cursor-pointer  text-2xl font-medium mt-3 mb-2 py-3 flex justify-content-between w-full border-black-alpha-10 border-bottom-1 hover:text-black-alpha-70 transition-duration-150"
             v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
            Всё в одном месте<i class="pi pi-fw pi-plus text-3xl text-primary"></i>
          </a>
          <div class="overflow-hidden transition-all transition-duration-400 transition-ease-in-out">
            <ul class="list-none">
              <li class="line-height-3 flex align-items-center mb-3 text-base"><i class="pi pi-circle-fill mr-3"
                                                                                  style="font-size:7px;"></i>
                Все необходимые инструменты собраны в одном приложении, которое станет вашим универсальным помощником
              </li>
              <li class="line-height-3 flex align-items-center mb-3 text-base"><i class="pi pi-circle-fill mr-3"
                                                                                  style="font-size:7px;"></i>
                В вашем личном кабинете вы можете легко отслеживать всю необходимую информацию
              </li>
              <li class="line-height-3 flex align-items-center mb-3 text-base"><i class="pi pi-circle-fill mr-3"
                                                                                  style="font-size:7px;"></i>
                Сервис платформы aihome.studio доступен с любого устройства
              </li>
            </ul>
          </div>
          <a tabindex="0"
             class="cursor-pointer  text-2xl font-medium mt-3 mb-2 py-3 flex justify-content-between w-full border-black-alpha-10 border-bottom-1 hover:text-black-alpha-70 transition-duration-150"
             v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
            Быстро и легко<i class="pi pi-fw pi-plus text-3xl text-primary"></i>
          </a>
          <div class="overflow-hidden hidden transition-all transition-duration-400 transition-ease-in-out">
            <ul class="list-none">
              <li class="line-height-3 flex align-items-center mb-3 text-base"><i class="pi pi-circle-fill mr-3"
                                                                                  style="font-size:7px;"></i>
                Мы помогаем вам решить все задачи быстро и без лишних хлопот. Регистрация на сайте, подготовка
                документов и проведение платежей происходит моментально и просто
              </li>
              <li class="line-height-3 flex align-items-center mb-3 text-base"><i class="pi pi-circle-fill mr-3"
                                                                                  style="font-size:7px;"></i>
                Наше приложение настолько простое в использовании, что ваш ребенок, обязательно, будет помогать вам
                выбирать идеальный дом
              </li>
              <li class="line-height-3 flex align-items-center mb-3 text-base"><i class="pi pi-circle-fill mr-3"
                                                                                  style="font-size:7px;"></i>
                Превратите выбор и постройку дома в увлекательное времяпрепровождение без сложностей и стрессов
              </li>
            </ul>
          </div>
          <a tabindex="0"
             class="cursor-pointer text-2xl font-medium mt-3 mb-2 py-3 flex justify-content-between w-full border-black-alpha-10 border-bottom-1 hover:text-black-alpha-70 transition-duration-150"
             v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
            Доступно и надёжно<i class="pi pi-fw pi-plus text-3xl text-primary"></i>
          </a>
          <div class="overflow-hidden hidden transition-all transition-duration-400 transition-ease-in-out">
            <ul class="list-none">
              <li class="line-height-3 flex align-items-center mb-3 text-base"><i class="pi pi-circle-fill mr-3"
                                                                                  style="font-size:7px;"></i>
                Независимо от выбранного вами проекта, вы сможете подобрать ипотеку у наших банковских партнеров, что
                сделает вашу мечту еще более доступной
              </li>
              <li class="line-height-3 flex align-items-center mb-3 text-base"><i class="pi pi-circle-fill mr-3"
                                                                                  style="font-size:7px;"></i>
                Различные ипотечные программы, включая семейную, с использованием материнского капитала, с
                государственной поддержкой, программы для ИТ-специалистов,
                помогут вам оптимизировать выплаты
              </li>
              <li class="line-height-3 flex align-items-center mb-3 text-base"><i class="pi pi-circle-fill mr-3"
                                                                                  style="font-size:7px;"></i>
                Мы ценим вашу личную информацию и обеспечиваем её надежную защиту.
                Используем передовые технологии безопасности, для того, чтобы ваши данные оставались только вашими
              </li>
            </ul>
          </div>
        </div>
      </div>
    </container-block>

    <container-block in-center flex align-items-start
                     class="flex-wrap surface-section px-4 xl:px-5 pt-8 pb-6 border-round shadow-2 mt-8">
      <div class="col-12 px-0 lg:col-6 py-4 lg:pr-4 flex-order-2 lg:flex-order-1">
        <div class="flex xl:px-5 py-2">
          <!-- <i class="pi pi-wallet text-4xl font-bold text-900"></i> -->
          <span class="text-5xl font-semibold text-primary">3</span>
          <div class="ml-3">
            <div class="text-900 font-medium text-2xl"><span class="text-primary">шага</span> на пути к цели</div>
            <p class="text-700">Зарегистрируйтесь, выберите проект, передайте работу нам </p>
          </div>
        </div>
        <div class="flex xl:px-5 py-2">
          <!-- <i class="pi pi-video text-4xl font-bold text-900"></i> -->
          <span class="text-5xl font-semibold text-primary">2</span>
          <div class="ml-3">
            <div class="text-900 font-medium text-2xl"><span class="text-primary">варианта:</span> строить или не
              строить?
            </div>
            <p class="text-700">
              Конечно строить! А с новыми программами ипотеки свой дом стал доступнее.
              Ипотечный калькулятор поможет спланировать расходы и подобрать программу государственной поддержки
            </p>
          </div>
        </div>
        <div class="flex xl:px-5 py-2">
          <!-- <i class="pi pi-tags text-4xl font-bold text-900"></i> -->
          <span class="text-5xl font-semibold text-primary">1</span>
          <div class="ml-3">
            <div class="text-900 font-medium text-2xl"><span class="text-primary">платформа,</span> которая сделает
              всё
            </div>
            <p class="text-700">
              Каталог проектов, реализация, строительный процесс и контроль, уникальный дизайн, оформление
              договоров,
              подбор проверенных специалистов, все это вы получаете в одном приложении
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 flex-order-1 lg:flex-order-2">
        <img src="/images/test/fin1.jpg" class="border-round w-full" alt="product"/>
      </div>
    </container-block>

    <faq-view/>
  </main-page>
</template>

<script setup>
import {register} from 'swiper/element/bundle'; //Swiper custom elements
import {ref} from 'vue'

import jsonData from '../test_data/houses.json'; //данные по домикам из файла
import {useRouter} from 'vue-router';
import MainPage from "@/components/mainPage.vue";
import ContainerBlock from "@/components/containerBlock.vue";
import FaqView from "@/views/view-faq.vue";

register();

const houses = ref(jsonData);

const router = useRouter();</script>

<style>

:root {
  --swiper-scrollbar-drag-bg-color: var(--primary-color) !important;
  --swiper-navigation-color: var(--primary-color) !important;
}

swiper-container::part(bullet-active) {
  background-color: var(--primary-color);
}

swiper-container::part(wrapper) {
  overflow: visible;
}

swiper-container::part(scrollbar) {
  margin-bottom: -4px;
  height: 2px;
}

swiper-container::part(button-prev) {
  width: 30px;
  height: 30px;
}

swiper-container::part(button-next) {
  width: 30px;
  height: 30px;
}

</style>

