import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
//import {ref} from 'vue';

const store = createStore({
    plugins: [createPersistedState()],

    state: {
        user: {
            id: null,
            name: null,
            phone: null,
            email: null
        },  
        isAuth: false,
        authStatus: 'login',
        showModal: false,
        showModalFilter: false,
        showSideBar: true,
        showWaiting: false,

        isPartner: false,

        toastMsg: {
            severity: null, 
            summary: null, 
            detail: null,
            life: 1500            
        }
    },

    mutations: {
        SET_USER(state, userData) {
            state.user = userData;
        },
        SET_PHONE(state, phone) {
            state.user.phone = phone;
        },
        SET_ISPARTNER(state, status) {
            state.isPartner = status;
        },
        SET_ISAUTH(state, status) {
            state.isAuth = status;
            console.log('isAuth: ' + status);
        },
        RESET_STATE(state) {
            // Сбросить значения состояния в исходное состояние
            state.user = [];
            state.authStatus = 'login';
            state.showModal = false;
        },
        SET_TOAST(state, toast) {
            state.toastMsg = toast;
            console.log('toastMsg:', state.toastMsg);
        },
        SET_AUTH_STATUS(state, status) {
            state.authStatus = status;
            console.log('authStatus:', state.authStatus);
        },
        SET_SHOW_MODAL(state, status) {
            state.showModal = status;
            console.log('showModal:', state.showModal);
        },
        SET_SHOW_SIDEBAR(state, status) {
            state.showSideBar = status;
            console.log('showSideBar:', state.showSideBar);
        },
        SET_SHOW_WAITING(state, status) {
            state.showWaiting = status;
            console.log('showSideBar:', state.showSideBar);
        },
        SET_SHOW_MODAL_FILTER(state, status) {
            state.showModalFilter = status;
            console.log('showModal:', state.showModalFilter);
        },
    },

    actions: {
        setUser({ commit }, user) {
            commit('SET_USER', user);
        },

        setPhone({ commit }, phone) {
            commit('SET_PHONE', phone);
        },

        setAuth({ commit }, status) {
            commit('SET_ISAUTH', status);
        },

        setIsPartner({ commit }, status) {
            commit('SET_ISPARTNER', status);
        },  
          
        loadUserData({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/get_user_data/','')
                    .then(response => {
                        const user = {
                            id: response.data.id,
                            name: response.data.name,
                            phone: response.data.phone,
                            email: response.data.email
                        };
                        commit('SET_USER', user);
                        commit('SET_ISAUTH', true);
                        console.log('loadUserData: ', user);
                        resolve(user);
                    })
                    .catch(error => {
                        console.error('Ошибка:', error);
                        reject(error);
                    });
            });
        },
      
    },

    getters: {
        getUser: (state) => {
            return state.user;
        },
        isPartner: (state) => {
            return state.isPartner;
        },    
        getPhone: (state) => {
            return state.user.phone;
        },
        isAuth: (state) => {
            return state.isAuth;
        }
    }
});

export default store;
