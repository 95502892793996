<template>
  <main-page>
    <container-block flex class="px-5">
      <div style="display: grid;" class="result-grid-wrp">
        <div :style="{'grid-column': isMobile?'span 1':'span 2'}" class="text-3xl title my-2 flex justify-content-between">
          <div>
            <span>Проекты домов</span>
            <span class="text-400 font-ultralight" style="text-wrap:nowrap;">
          (выбрано {{ filteredHouses.length }} из {{ jsonData.length }})
        </span>
          </div>
          <a class="cursor-pointer block lg:hidden text-700 align-self-center"  v-show="isMobile" @click="showModalFilter = !showModalFilter">
            <i class="pi pi-filter text-2xl"></i>
          </a>
        </div>
        <component
            :is="isMobile?'sidebarItem':'div'"
            v-model:visible="showModalFilter"
            header="Фильтры"
            position="bottom"
            :class="{'sidebar-mobile': isMobile, 'w-full': !isMobile}">
          <view-filter-range class="py-2" label="Стоимость проекта, ₽"
                             :step="100000"
                             :max="defaultFilterValues.priceMax"
                             :min="defaultFilterValues.priceMin"
                             v-model="filter.price"/>
          <view-filter-range class="py-2"
                             label="Площадь, м2"
                             :step="1"
                             :max="defaultFilterValues.areaMax"
                             :min="defaultFilterValues.areaMin"
                             v-model="filter.area"/>
          <div class="w-full flex flex-wrap my-2">
            <label class="w-full p-1">Этажей</label>
            <select-button class="grow-select-button" v-model="filter.floors"
                           :options='[{"id": 1, "name": "1"}, {"id": 2, "name": "2"}, {"id": 3, "name": "3+"}]'
                           optionLabel="name" multiple aria-labelledby="multiple"/>
          </div>
          <div class="w-full flex flex-wrap my-2">
            <label class="w-full p-1">Кол-во спален</label>
            <select-button class="grow-select-button" v-model="filter.bedrooms"
                           :options='[{"id": 1, "name": "1"}, {"id": 2, "name": "2"}, {"id": 3, "name": "3"}, {"id": 4, "name": "4+"}]'
                           optionLabel="name" multiple aria-labelledby="multiple"/>
          </div>
          <div class="w-full flex flex-wrap my-2">
            <label class="w-full p-1">Кол-во санузлов</label>
            <select-button class="grow-select-button" v-model="filter.bathrooms"
                           :options='[{"id": 1, "name": "1"}, {"id": 2, "name": "2"}, {"id": 3, "name": "3+"}]'
                           optionLabel="name" multiple aria-labelledby="multiple"/>
          </div>
          <multi-select class="w-full my-2" v-model="filter.selectedStyles" :options="styles" optionLabel="name"
                        placeholder="Стиль"/>
          <multi-select class="w-full my-2" v-model="filter.selectedFoundationTypes" :options="foundationTypes"
                        optionLabel="name" placeholder="Тип фундамента"/>
          <multi-select class="w-full my-2" v-model="filter.selectedWallMaterials" :options="wallMaterials"
                        optionLabel="name" placeholder="Материал стен"/>
          <div class="w-full flex align-items-center py-2 justify-content-between">
            <label class="px-2">Камин</label>
            <select-button v-model="filter.fireplace"
                           :options='[{"id": true, "name": "да"}, {"id": false, "name": "нет"}]'
                           optionLabel="name" aria-labelledby="basic"/>
          </div>
          <div class="w-full flex align-items-center py-2 justify-content-between">
            <label class="px-2">Гараж</label>
            <select-button v-model="filter.garage"
                           :options='[{"id": true, "name": "да"}, {"id": false, "name": "нет"}]'
                           optionLabel="name" aria-labelledby="basic"/>
          </div>
          <div class="w-full flex align-items-center py-2 justify-content-between">
            <label class="px-2">Чердак</label>
            <select-button v-model="filter.attic"
                           :options='[{"id": true, "name": "да"}, {"id": false, "name": "нет"}]'
                           optionLabel="name" aria-labelledby="basic"/>
          </div>
          <div class="w-full flex align-items-center py-2 justify-content-between">
            <label class="px-2">Подвал</label>
            <select-button v-model="filter.basement"
                           :options='[{"id": true, "name": "да"}, {"id": false, "name": "нет"}]'
                           optionLabel="name" aria-labelledby="basic"/>
          </div>
          <div class="w-full flex align-items-center py-2 justify-content-between">
            <label class="px-2">Терраса</label>
            <select-button v-model="filter.terrace"
                           :options='[{"id": true, "name": "да"}, {"id": false, "name": "нет"}]'
                           optionLabel="name" aria-labelledby="basic"/>
          </div>
        </component>
        <div style="display: grid; gap: 20px;" class="result-grid">
          <div v-for="house in filteredHouses" :key="house.id"
               class="flex flex-row justify-content-center align-items-start ">
            <ViewHouseCard
                :name="house.name"
                :area="house.area"
                :material="house.wall_material"
                :bedrooms="house.bedrooms"
                :bathrooms="house.bathrooms"
                :mortgage="house.mortgage"
                :price="house.price"
                :photos="house.photos"
            ></ViewHouseCard>
          </div>
        </div>
      </div>
    </container-block>
  </main-page>
</template>

<script setup>
import {register} from 'swiper/element/bundle';
import {computed, onMounted, reactive, ref, watch} from 'vue'
import ViewFilterRange from './view-filter-range.vue'
/* import Paginator from 'primevue/paginator'; */
import jsonData from '../test_data/houses.json';
import MainPage from "@/components/mainPage.vue";
import ContainerBlock from "@/components/containerBlock.vue";
//данные по домикам из файла
register();

//const houses = ref(jsonData);

const isMobile = ref(document.body.clientWidth < 1000)

addEventListener("resize", () => {
  setTimeout(() => {
    isMobile.value = document.body.clientWidth < 1000;
  }, 100)
});

const defaultFilterValues = {
  priceMin: 4500000,
  priceMax: 12200000,
  areaMin: 50,
  areaMax: 210,
  floorsMin: 1,
  floorsMax: 3
}

const filter = reactive({
  price: [defaultFilterValues.priceMin, defaultFilterValues.priceMax],
  area: [defaultFilterValues.areaMin, defaultFilterValues.areaMax],
  floors: [],
  bedrooms: [],
  bathrooms: [],
  selectedStyles: [],
  selectedFoundationTypes: [],
  selectedWallMaterials: [],
  fireplace: null,
  terrace: null,
  basement: null,
  attic: null,
  garage: null
});

const filteredHouses = computed(() => {
  let floorsFilter = filter.floors.map(x => x.id);
  let bedrooms = filter.bedrooms.map(x => x.id);
  let bathrooms = filter.bathrooms.map(x => x.id);

  let selectedStyles = filter.selectedStyles.map(x => x.id);
  let selectedFoundationTypes = filter.selectedFoundationTypes.map(x => x.id);
  let selectedWallMaterials = filter.selectedWallMaterials.map(x => x.id);

  return jsonData.filter(x =>
      x.price >= filter.price[0] && x.price <= filter.price[1] &&
      x.area >= filter.area[0] && x.area <= filter.area[1] &&
      (floorsFilter.length === 0 || floorsFilter.indexOf(x.floors) > -1) &&
      (bedrooms.length === 0 || bedrooms.indexOf(x.bedrooms) > -1) &&
      (bathrooms.length === 0 || bathrooms.indexOf(x.bathrooms) > -1) &&
      (selectedStyles.length === 0 || selectedStyles.indexOf(x.style) > -1) &&
      (selectedFoundationTypes.length === 0 || selectedFoundationTypes.indexOf(x.foundation) > -1) &&
      (selectedWallMaterials.length === 0 || selectedWallMaterials.indexOf(x.wall_material) > -1) &&
      (filter.fireplace == null || x.fireplace === filter.fireplace.id) &&
      (filter.terrace == null || x.terrace === filter.terrace.id) &&
      (filter.basement == null || x.basement === filter.basement.id) &&
      (filter.attic == null || x.attic === filter.attic.id) &&
      (filter.garage == null || x.garage === filter.garage.id));
})

const styles = [...(new Set(jsonData.map(x => x.style)))].sort().map(x => ({
  id: x,
  name: x.charAt(0).toUpperCase() + x.slice(1)
}));
const foundationTypes = [...(new Set(jsonData.map(x => x.foundation)))].sort().map(x => ({
  id: x,
  name: x.charAt(0).toUpperCase() + x.slice(1)
}));
const wallMaterials = [...(new Set(jsonData.map(x => x.wall_material)))].sort().map(x => ({
  id: x,
  name: x.charAt(0).toUpperCase() + x.slice(1)
}));

watch(
    () => filter,
    () => {

    },
    {deep: true}
)

const isLoaded = ref(false);
onMounted(() => {
  isLoaded.value = true;
});

let showModalFilter = ref(false)

</script>

<style>

:root {
  --swiper-scrollbar-drag-bg-color: var(--primary-color) !important;
  --swiper-navigation-color: var(--primary-color) !important;

}

swiper-container::part(bullet-active) {
  background-color: var(--primary-color);
}

swiper-container::part(wrapper) {
  overflow: visible;
}

swiper-container::part(scrollbar) {
  margin-bottom: -4px;
  height: 2px;
}

swiper-container::part(button-prev) {
  width: 30px;
  height: 30px;
}

swiper-container::part(button-next) {
  width: 30px;
  height: 30px;
}

.no-border-right input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.no-border-left input {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-width-fix input {
  width: 100%;
}

.grow-select-button {
  width: 100%;
  display: flex;
}

.grow-select-button div {
  flex-grow: 1;
}

.sidebar-mobile {
  height: auto;
}

.result-grid-wrp {
  grid-template-columns: minmax(250px, 400px) auto;
  column-gap: 30px;
}

@media screen and (max-width: 1000px) {
  .result-grid-wrp {
    grid-template-columns: auto;
    column-gap: 0px;
  }
}

.result-grid {
  grid-template-columns: repeat(5, 1fr);
}

@media screen and (max-width: 2120px) {
  .result-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1684px) {
  .result-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1344px) {
  .result-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 700px) {
  .result-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>

