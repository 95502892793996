<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script setup>


</script>


<style>
    .container{
        overflow-x: hidden !important;
    }


    @keyframes increaseWidth {
        0% {
            width: 0rem; /* начальная ширина */
        }
        100% {
            width: 18rem; /* конечная ширина */
        }
    }

    @keyframes decreaseWidth {
        0% {
            width: 18rem; /* начальная ширина */
        }
        100% {
            width: 0rem; /* конечная ширина */
        }

    }    

    .increase {
        animation: increaseWidth 0.4s ease;
        animation-fill-mode: forwards;
    }
    .decrease {
        animation: decreaseWidth 0.4s ease;
        animation-fill-mode: forwards;
    }    

    .text-right-ch-input input {
      text-align: right;
    }

    @media screen and (max-width: 1200px) {
      .p-tabview .p-tabview-panels {
        padding: 2rem;
      }

      :root {
        --swiper-navigation-sides-offset: -2px;
      }
    }
    .p-sidebar-close {
      all: unset;
    }

    .p-sidebar-close {
      color: #fff;
    }
</style>
