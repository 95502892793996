<template>
    <div class="flex flex-column justify-content-center align-items-center w-full min-h-screen pt-8">
        
        <!-- данные пользователя -->

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11">
            <div class="font-medium text-3xl text-900 mb-3">{{ phone }}</div>
            <div class="text-500 mb-5">Данные пользователя</div>
            <ul class="list-none p-0 m-0">
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div class="text-500 w-9 md:w-2 font-medium">Имя</div>
                    <div v-show="!editingName" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ name }}</div>
                    <InputText v-show="editingName" ref="inputName" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-model="name" size="medium" @keydown.enter="sendName"/>
                    <div class="w-3 md:w-2 flex justify-content-end">
                        <ButtonItem v-if="!editingName" @click="editName" label="" icon="pi pi-pencil" class="p-button-text"></ButtonItem>
                        <ButtonItem v-else @click="sendName" label="" icon="pi pi-check"  severity="primary"></ButtonItem>
                    </div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Почта</div>
                    <div v-show="!editingMail" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ email }}</div>
                    <InputText v-show="editingMail" ref="inputMail" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-model="email" size="medium" @keydown.enter="sendName"/>
                    <div class="w-6 md:w-2 flex justify-content-end">
                        <ButtonItem v-if="!editingMail" @click="editMail" label="" icon="pi pi-pencil" class="p-button-text"></ButtonItem>
                        <ButtonItem v-else @click="sendName" label="" icon="pi pi-check"  severity="primary"></ButtonItem>
                    </div>
                </li>                


            </ul>
        </div>

        <!-- вопросы -->
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Внесите, пожалуйста, кадастровый номер участка (их может быть несколько)
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <InputText id="number" v-model="cadastralNumber" />
            </div>
                   
        </div>

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                У Вас есть геодезическая съемка участка?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueGeo" :options="optionsYN" size="small"  /> 
            </div>
        </div>        

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Какой материал для строительства дома Вы выбираете?
            </div>
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in houseTypes" :key="category.key" class="flex align-items-center">
                    <RadioButton v-model="selectedHouseType" :inputId="category.key" name="dynamic" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>        
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Какой архитектурный стиль Вы выбираете?
            </div>
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in houseStyles" :key="category.key" class="flex align-items-center">
                    <RadioButton v-model="selectedHouseStyle" :inputId="category.key" name="dynamic" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>        
        </div>   
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Выберите необходимую площадь дома
            </div>
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <InputGroup>
                    <InputText v-model.number="area" class="w-full" />
                    <InputGroupAddon>кв.м</InputGroupAddon>
                </InputGroup>
                <SliderItem  :max="500" :step="10" v-model="area" class="w-full" :key="3-1" />
            </div>        
        </div>         

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Сколько этажей Вы хотите?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="value3" :options="options3" size="small"  /> 
            </div>
        </div>      

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Вам необходим мансардный этаж?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueMansard" :options="optionsYN" size="small"  /> 
            </div>
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Вам необходим подвальный этаж?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueCellar" :options="optionsYN" size="small"  /> 
            </div>
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Уточните какое колличество спален Вы хотите?
            </div>
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <InputGroup>
                    <InputText v-model.number="bedrooms" class="w-full" />
                    <!-- <InputGroupAddon></InputGroupAddon> -->
                </InputGroup>
                <SliderItem  :max="10" :step="1" v-model="bedrooms" class="w-full" :key="4-1" />
            </div>        
        </div>           

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Желаете объединить пространство кухни и гостинной?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueKitchen" :options="optionsYN" size="small"  /> 
            </div>
        </div>

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Какие дополнительные помещения необходимы?
            </div>
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in houseRooms" :key="category.key" class="flex align-items-center">
                    <CheckboxItem v-model="selectedRooms" :inputId="category.key" name="category" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>        
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Какие летние помещения Вам необходимы?
            </div>
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in houseSummer" :key="category.key" class="flex align-items-center">
                    <CheckboxItem v-model="selectedSummer" :inputId="category.key" name="category" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>        
        </div>           
    
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Необходим ли встроенный гараж?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueGarage" :options="optionsYN" size="small"  /> 
            </div>
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Необходима ли парковка для машин?
            </div> 
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in radioParking" :key="category.key" class="flex align-items-center">
                    <RadioButton v-model="selectedParking" :inputId="category.key" name="dynamic" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Необходим ли Вам отдельно-стоящий гараж?
            </div> 
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in radioGarage" :key="category.key" class="flex align-items-center">
                    <RadioButton v-model="selectedGarage" :inputId="category.key" name="dynamic" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>
        </div>        

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Необходима ли баня?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueSauna" :options="optionsYN" size="small"  /> 
            </div>
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Необходим ли бассейн?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valuePool" :options="optionsYN" size="small"  /> 
            </div>
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Необходима ли беседка?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valuePavilion" :options="optionsYN" size="small"  /> 
            </div>
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Необходима ли мангальная?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueBBQ" :options="optionsYN" size="small"  /> 
            </div>
        </div>
        
        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Необходим ли сарай?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueBarn" :options="optionsYN" size="small"  /> 
            </div>
        </div>

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Необходим ли гостевой домик?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueGuestHouse" :options="optionsYN" size="small"  /> 
            </div>
        </div>  

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Потребуется разработка дизайн проекта?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueDesign" :options="optionsYN" size="small"  /> 
            </div>
        </div>  

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Потребуется разработка ландшафтного дизайна?
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueLandscape" :options="optionsYN" size="small"  /> 
            </div>
        </div>  

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Водоснабжение
            </div> 
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in radioWaterSupply" :key="category.key" class="flex align-items-center">
                    <RadioButton v-model="selectedWaterSupply" :inputId="category.key" name="dynamic" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>
        </div> 

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Канализация
            </div> 
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in radioSewerage" :key="category.key" class="flex align-items-center">
                    <RadioButton v-model="selectedSewerage" :inputId="category.key" name="dynamic" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>
        </div>         

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Газоснабжение
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valueGas" :options="optionsYN" size="small"  /> 
            </div>
        </div>         

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Отопление
            </div> 
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in radioHeating" :key="category.key" class="flex align-items-center">
                    <RadioButton v-model="selectedHeating" :inputId="category.key" name="dynamic" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>
        </div>  

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Вентиляция
            </div> 
            <div class="flex flex-column row-gap-3 xl:w-5 w-11">
                <div v-for="category in radioVentilation" :key="category.key" class="flex align-items-center">
                    <RadioButton v-model="selectedVentilation" :inputId="category.key" name="dynamic" :value="category.name" />
                    <label :for="category.key" class="ml-3">{{ category.name }}</label>
                </div>
            </div>
        </div>         

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11 flex flex-wrap gap-6">
            <div class="xl:w-5 w-11">
                Централизованное электроснабжение
            </div> 
            <div class="flex flex-column xl:w-5 w-11">
                <SelectButton v-model="valuePower" :options="optionsYN" size="small"  /> 
            </div>
        </div>     




        <!-- сохраняем данные -->

        <div class="surface-card p-4 mt-4 shadow-2 border-round xl:w-6 w-11">
            <ButtonItem  label="Отправить данные" icon="pi pi-check" class="w-12" severity="primary" @click="sendRequest" ></ButtonItem>
        </div>        

    </div>
</template>

<script setup>

    //общий 
    import { ref, nextTick } from "vue";
    
    import axios from 'axios';
    
    import { useStore } from "vuex";
    const store = useStore();

    import { updateUser, addToast } from './functions';

    const name = ref(store.state.user.name);
    const email = ref(store.state.user.email);
    const phone = ref(store.state.user.phone);
    const userID = ref(store.state.user.id);

    const editingName = ref(false);
    const editingMail = ref(false);
    //const realNum = ref(null);

    const cadastralNumber = ref(null);

    const optionsYN = ref(['Нет', 'Да']);

    const valueGeo = ref('Нет');
    const valueCellar = ref('Нет');
    const valueMansard = ref('Нет');
    const valueKitchen = ref('Нет');
    const valueGarage = ref('Нет');
    const valueSauna = ref('Нет');
    const valuePool = ref('Нет');
    const valuePavilion = ref('Нет');
    const valueBBQ = ref('Нет');
    const valueBarn = ref('Нет');
    const valueGuestHouse = ref('Нет');
    const valueDesign = ref('Нет');
    const valueLandscape = ref('Нет');
    const valueGas = ref('Нет');
    const valuePower = ref('Нет');

    const value3 = ref('1');
    const options3 = ref(['1', '2', '3']);

    const area = ref(150);
    const bedrooms = ref(3);

    const selectedHouseType = ref('Не определился');
    const houseTypes = ref([
        { name: 'Кирпич', key: '1-1' },
        { name: 'Бетонный блок', key: '1-2' },
        { name: 'Дерево', key: '1-3' },
        { name: 'Монолит', key: '1-4' },
        { name: 'Клееный брус', key: '1-5' },
        { name: 'Щитовые панели', key: '1-6' },
        { name: 'Не определился', key: '1-7' }
    ]);

    const selectedHouseStyle = ref('Классический');
    const houseStyles = ref([
        { name: 'Классический', key: '2-1' },
        { name: 'Минимализм', key: '2-2' },
        { name: 'Русский', key: '2-3' },
        { name: 'Хай-тек', key: '2-4' },
        { name: 'Фахверк', key: '2-5' },
        { name: 'Райт', key: '2-6' }
    ]);

    const selectedRooms = ref(['Rooms']);
    const houseRooms = ref([
        { name: 'Кабинет', key: '5-1' },
        { name: 'Мастерская', key: '5-2' },
        { name: 'Игровая', key: '5-3' },
        { name: 'Тренажерный зал', key: '5-4' },
        { name: 'Бильярдная', key: '5-5' },
        { name: 'Кинозал', key: '5-6' },
        { name: 'Спа-зона', key: '5-7' }
    ]);

    const selectedSummer = ref(['Summer']);
    const houseSummer = ref([
        { name: 'Веранда', key: '6-1' },
        { name: 'Балкон', key: '6-2' },
        { name: 'Терраса', key: '6-3' }
    ]);

    const selectedParking = ref('Нет');
    const radioParking = ref([
        { name: 'С навесом', key: '7-1' },
        { name: 'Без навеса', key: '7-2' },
        { name: 'Нет', key: '7-3' }
    ]);

    const selectedGarage = ref('Нет');
    const radioGarage = ref([
        { name: 'Неотапливаемый', key: '8-1' },
        { name: 'Отапливаемый', key: '8-2' },
        { name: 'Нет', key: '8-3' }
    ]);

    const selectedWaterSupply = ref('Автономное');
    const radioWaterSupply = ref([
        { name: 'Автономное', key: '9-1' },
        { name: 'Централизованное', key: '9-2' }
    ]);

    const selectedSewerage = ref('Септик');
    const radioSewerage = ref([
        { name: 'Септик', key: '10-1' },
        { name: 'Централизованная', key: '10-2' }
    ]);

    const selectedHeating = ref('Автономное');
    const radioHeating = ref([
        { name: 'Автономное', key: '11-1' },
        { name: 'Централизованное', key: '11-2' }
    ]);

    const selectedVentilation = ref('Естественная');
    const radioVentilation = ref([
        { name: 'Естественная', key: '12-1' },
        { name: 'Приточно-вытяжная', key: '12-2' },
        { name: 'Воздушно климатическая система', key: '12-3' }
    ]);



    const sendRequest = () => {
        const senddata = {
            request: requestArch()
        };
        axios.post('/add_arch_request/', senddata)
            .then(response => {
                if (response.data.status === 'ok'){
                    addToast({
                        severity:'success', 
                        summary:'Данные сохранены', 
                        detail: ' ',
                        life: 3000
                    }); 
                }
                console.log('Ответ сервера: ', response.data);
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });                
    }

    const inputName = ref();
    const editName = () => {
        editingName.value = true;
        nextTick(() => { inputName.value.$el.focus(); });
    }

    const inputMail = ref();
    const editMail = () =>{
        editingMail.value = true;
        nextTick(() => { inputMail.value.$el.focus(); });
    }

    const sendName = () => {

    editingName.value = false;
    editingMail.value = false;

    updateUser({
        id: userID.value,
        phone: phone.value,
        name: name.value,
        email: email.value
    });
    };

    const requestArch = () => {
                    const request = [
                        {
                            question : 'Внесите, пожалуйста, кадастровый номер участка (их может быть несколько)',
                            answer: cadastralNumber.value,
                        },
                        {
                            question : 'У Вас есть геодезическая съемка участка?',
                            answer: valueGeo.value,
                        },
                        {
                            question : 'Какой материал для строительства дома Вы выбираете?',
                            answer: selectedHouseType.value,
                        },
                        {
                            question : 'Какой архитектурный стиль Вы выбираете?',
                            answer: selectedHouseStyle.value,
                        },
                        {
                            question : 'Выберите необходимую площадь дома',
                            answer: area.value,
                        },
                        {
                            question : 'Сколько этажей Вы хотите?',
                            answer: value3.value,
                        },
                        {
                            question : 'Вам необходим мансардный этаж?',
                            answer: valueMansard.value,
                        }, 
                        {
                            question : 'Вам необходим подвальный этаж?',
                            answer: valueCellar.value,
                        }, 
                        {
                            question : 'Уточните какое колличество спален Вы хотите?',
                            answer: bedrooms.value,
                        }, 
                        {
                            question : 'Желаете объединить пространство кухни и гостинной?',
                            answer: valueKitchen.value,
                        }, 
                        {
                            question : 'Какие дополнительные помещения необходимы?',
                            answer: selectedRooms.value,
                        },
                        {
                            question : 'Какие летние помещения Вам необходимы?',
                            answer: selectedSummer.value,
                        },  
                        {
                            question : 'Необходим ли встроенный гараж?',
                            answer: valueGarage.value,
                        },  
                        {
                            question : 'Необходима ли парковка для машин?',
                            answer: selectedParking.value,
                        },  
                        {
                            question : 'Необходим ли Вам отдельно-стоящий гараж?',
                            answer: selectedGarage.value,
                        },  
                        {
                            question : 'Необходима ли баня?',
                            answer: valueSauna.value,
                        },  
                        {
                            question : 'Необходим ли бассейн?',
                            answer: valuePool.value,
                        },  
                        {
                            question : 'Необходима ли беседка?',
                            answer: valuePavilion.value,
                        },  
                        {
                            question : 'Необходима ли мангальная?',
                            answer: valueBBQ.value,
                        },  
                        {
                            question : 'Необходим ли сарай?',
                            answer: valueBarn.value,
                        },
                        {
                            question : 'Необходим ли гостевой домик?',
                            answer: valueGuestHouse.value,
                        },  
                        {
                            question : 'Потребуется разработка дизайн проекта?',
                            answer: valueDesign.value,
                        },  
                        {
                            question : 'Потребуется разработка ландшафтного дизайна?',
                            answer: valueLandscape.value,
                        },  
                        {
                            question : 'Водоснабжение',
                            answer: selectedWaterSupply.value,
                        },  
                        {
                            question : 'Канализация',
                            answer: selectedSewerage.value,
                        },  
                        {
                            question : 'Газоснабжение',
                            answer: valueGas.value,
                        },
                        {
                            question : 'Отопление',
                            answer: selectedHeating.value,
                        }, 
                        {
                            question : 'Вентиляция',
                            answer: selectedVentilation.value,
                        }, 
                        {
                            question : 'Централизованное электроснабжение',
                            answer: valuePower.value,
                        },                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                    ];
                    return { request }
    }     
</script>

