<template>
    <!-- <div class="w-screen h-screen fixed flex justify-content-center align-items-center z-0" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd"></div> -->
    <div></div>
</template>
  
<script setup>
    import { onMounted } from 'vue';
import { useStore } from 'vuex';
    const store = useStore();
    
    let menuOffset = 0;
    let startX = 0;
    let startY = 0;
     
    const handleTouchStart = (event) => {
        const touch = event.touches[0];
        startX = touch.clientX;
        startY = touch.clientY;
    };
    
    const handleTouchMove = (event) => {
        const touch = event.touches[0];
        const deltaX = touch.clientX - startX;
        const deltaY = touch.clientY - startY;
        startX = touch.clientX;
        startY = touch.clientY;
        
        menuOffset += deltaX;

        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            event.preventDefault();
        }        
    };
    
    const handleTouchEnd = () => {
        if (menuOffset > 120) {
            store.commit('SET_SHOW_SIDEBAR', true)
        } 
        if (menuOffset < -55) {
            store.commit('SET_SHOW_SIDEBAR', false)
        }
        menuOffset = 0;
    };

    onMounted (() => {
        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
    });

</script>
  


 
