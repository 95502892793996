<template>
  <div class="w-full min-w-screen min-h-screen flex flex-column align-items-center surface-ground overflow-x-hidden">
    <ToastItem class="w-11 md:w-2" /><!-- Тут живут тосты -->
    <SlideOver></SlideOver> <!-- Тут модальная выползуха -->
    <!-- Главное меню -->
    <MenuItem class="z-3"></MenuItem>
    <!-- Главное меню -->
    <slot></slot>
    <div class="h-5rem"></div>
    <!-- Футер -->
    <FooterItem></FooterItem>
    <!-- Футер -->
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>