<template>
  <container-block style="z-index: 1;">
    <div class="grid grid-nogutter surface-section px-4 py-4 md:px-6 lg:px-8 border-top-1 surface-border z-1 lg:z-4">
      <div class="col-12 lg:col-6 lg:border-right-1 surface-border">
        <img src="/images/ci/logo_works_color_vert.svg" class="w-4rem mx-auto lg:mx-0" alt="Peak logo"/>
        <span class="text-900 block mt-4 mr-3">
                    <a href="tel:+79857778303"
                       class="font-semibold text-color no-underline">+7 985 777 83 03</a> <br><br>

                    г. Москва, Бутлерова 17, кабинет А27 <br>
                    понедельник - пятница с 10:00 до 19:00
                </span>
        <span class="text-500 block mt-4">
                    <a tabindex="0" class="cursor-pointer mr-3 lg:mt-0 block">
                        <i class="pi pi-telegram surface-section p-1 border-circle  text-3xl"></i>
                        <i class="pi pi-whatsapp surface-section p-1 border-circle  text-3xl"></i>
                    </a>
                </span>
      </div>
      <div class="col-6 md:col-6 lg:col-3 mt-4 lg:mt-0 lg:pl-4 flex flex-column">
        <span class="text-900 text-lg font-medium block title">О компании</span>
        <ul class="list-none p-0">
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Компания</a></li>
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Сервисы</a></li>
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Пресса о нас</a></li>
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Партнеры</a></li>
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Контакты</a></li>
        </ul>
      </div>
      <div class="col-6 lg:col-3 md:col-6 flex mt-4 lg:mt-0 lg:pl-4 flex-column">
        <span class="text-900 text-lg font-medium block title">Кабинет</span>
        <ul class="list-none p-0">
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Аккаунт</a></li>
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Мои объекты</a></li>
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Документы</a></li>
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Финансы</a></li>
          <li><a class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Чаты</a></li>
        </ul>
      </div>
    </div>
    <div
        class="bg-primary-600 py-6 lg:py-4 px-4 md:px-6 lg:px-8 flex flex-column lg:flex-row justify-content-start align-items-start font-medium z-1 lg:z-4">
      <ul class="list-none p-0 mb-0 flex flex-column md:flex-row flex-order-1 lg:flex-order-0 mt-4 lg:mt-0">
        <li class="mr-4 mt-3 lg:mt-0">
          <a class="cursor-pointer text-0 hover:text-900">Инвесторам</a>
        </li>
        <li class="mr-4 mt-3 lg:mt-0">
          <a class="cursor-pointer text-0 hover:text-900">Защита&nbsp;данных</a>
        </li>
        <li class="mr-4 mt-3 lg:mt-0">
          <a tabindex="0" class="cursor-pointer text-0 hover:text-900">Условия&nbsp;использования</a>
        </li>
        <li class="mr-4 mt-3 lg:mt-0">
          <a tabindex="0" class="cursor-pointer text-0 hover:text-900">Для&nbsp;прессы</a>
        </li>
      </ul>

      <div class="flex w-full justify-content-start lg:justify-content-end  flex-order-0 lg:flex-order-1 text-0 ">

        © 2024, AI Home Studio


      </div>
    </div>
  </container-block>
</template>