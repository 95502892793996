<template>
  <main-page>
    <container-block flex no-align-items no-justify-content>
      <SideBar></SideBar>

      <div class="w-full flex flex-column justify-content-center align-items-center min-h-screen pt-8">
        <!-- данные пользователя -->

        <div class="surface-card p-4 mt-4 shadow-2 border-round md:w-8 w-11">
          <div class="font-medium text-3xl text-900 mb-3">{{ phone }}</div>
          <div class="text-500 mb-5">Данные пользователя</div>
          <ul class="list-none p-0 m-0">
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-9 md:w-2 font-medium">Имя</div>
              <div v-show="!editingName" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ name }}</div>
              <InputText v-show="editingName" ref="inputName" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-model="name" size="medium" @keydown.enter="sendName"/>
              <div class="w-3 md:w-2 flex justify-content-end">
                <ButtonItem v-if="!editingName" @click="editName" label="" icon="pi pi-pencil" class="p-button-text"></ButtonItem>
                <ButtonItem v-else @click="sendName" label="" icon="pi pi-check"  severity="primary"></ButtonItem>
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Почта</div>
              <div v-show="!editingMail" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ email }}</div>
              <InputText v-show="editingMail" ref="inputMail" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-model="email" size="medium" @keydown.enter="sendName"/>
              <div class="w-6 md:w-2 flex justify-content-end">
                <ButtonItem v-if="!editingMail" @click="editMail" label="" icon="pi pi-pencil" class="p-button-text"></ButtonItem>
                <ButtonItem v-else @click="sendName" label="" icon="pi pi-check"  severity="primary"></ButtonItem>
              </div>
            </li>


          </ul>
        </div>

        <!-- данные организаций -->
        <div v-for="organization in organizations" :key="organization.id" class="surface-card p-4 mt-4 shadow-2 border-round md:w-8  w-11">
          <div class="font-medium text-3xl text-900 mb-3">
            {{ organization.name }}
          </div>
          <div class="text-500 mb-5">Статус аккредитации: <span :class="['font-bold', getStatusColor(organization.sbisstatus)]">{{ organization.sbisstatus }}</span></div>

          <ul class="list-none p-0 m-0">
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">ИНН</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ organization.inn }}</div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">ОКВЭД</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ organization.okved }}</div>
            </li>
            <li v-if="showDetails(organization.sbisstatus)" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Регион</div>
              <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                <MultiSelect v-model="organization.selectedCities" :options="cities" @change="onSelectionChange(organization)" optionLabel="name" placeholder="Регион деятельности" :maxSelectedLabels="100" class="w-full" />
              </div>

            </li>
            <li v-if="showDetails(organization.sbisstatus)" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Виды работ и услуг</div>
              <div class="text-900 w-full md:flex-order-0 flex-order-1">
                <Tree :value="treeData" selectionMode="checkbox" v-model:selectionKeys="organization.selectedNodeKeys" @nodeUnselect="onSelectionChange(organization)"  @nodeSelect="onSelectionChange(organization)" ></Tree>
              </div>

            </li>


          </ul>
        </div>

        <!-- добавляем организацию -->

        <div class="surface-card p-4 mt-4 shadow-2 border-round md:w-8 w-11 mb-7">
          <ButtonItem v-if="!fieldInn" label="Добавить организацию" icon="pi pi-plus" class="p-button-text" @click="newOrganization" ></ButtonItem>
          <div v-else class="flex flex-row">
                <span class="p-float-label" style="margin-right: 10px;">
                    <InputMask id="txt" type="tel" ref="inputINN" v-model="newOrganizationINN" size="small" mask="9999999999? 99" @keydown.enter="sendINN"/>
                    <label for="txt">Введите ИНН</label>
                </span>
            <ButtonItem icon="pi pi-check" style="color:white" severity="primary"  @click="sendINN" size="normal" :disabled="!readyINN"  ></ButtonItem>
          </div>
        </div>

        <ViewSwipe class="lg:hidden"></ViewSwipe> <!-- свайп для меню -->
      </div>
    </container-block>

  </main-page>
</template>

<script setup>
//общий
import { ref, computed, onMounted, nextTick } from "vue";
import axios from 'axios';
import { useStore } from "vuex";
import MultiSelect from 'primevue/multiselect';
import Tree from 'primevue/tree';

import { updateUser, addToast } from './functions';
import MainPage from "@/components/mainPage.vue";
import ContainerBlock from "@/components/containerBlock.vue";

const store = useStore();

const name = ref(store.state.user.name);
const email = ref(store.state.user.email);
const phone = ref(store.state.user.phone);
const userID = ref(store.state.user.id);

const inputName = ref();
const inputMail = ref();
const inputINN = ref();


const cities = ref([
  { name: 'Москва', code: '63' },
  { name: 'Московская область', code: '65' },
  { name: 'Санкт-Петербург', code: '457' },
  { name: 'Ленинградская область', code: '459' }
]);



const organizations = ref();
const fieldInn = ref();
const newOrganizationINN = ref('');


const editingName = ref(false);
const editingMail = ref(false);
const treeData = ref([]);


const editName = () => {
  editingName.value = true;
  nextTick(() => { inputName.value.$el.focus(); });
};

const editMail = () => {
  editingMail.value = true;
  nextTick(() => { inputMail.value.$el.focus(); });
};

const sendName = () => {

  editingName.value = false;
  editingMail.value = false;

  updateUser({
    id: userID.value,
    phone: phone.value,
    name: name.value,
    email: email.value
  });
};


const getOrganizations = (userID) => {
  const request = {
    user_id: userID
  };
  axios
      .post('/get_organizations/', request)
      .then(response => {
        organizations.value = response.data;
        console.log('Запрос организаций:', response.data);
      })
      .catch(error => {
        console.error('Ошибка:', error);
      });
};

const addOrganization = () => {
  const request = {
    user_id: userID.value,
    inn: newOrganizationINN.value.replace(/[_\s]/g, "")
  };
  axios
      .post('/add_organization/', request)
      .then(response => {
        console.log('Создание организации:', response.data);
        if (response.data.status === 'duplicate'){
          addToast({
            severity:'error',
            summary:'ИНН уже зарегистрирован',
            detail: 'Свяжитесь с нами для уточнения деталей',
          });
        }
        if (response.data.status === 'noCompany'){
          addToast({
            severity:'error',
            summary:'Компании с таким ИНН не существует',
            detail: 'Свяжитесь с нами для уточнения деталей',
          });
        }
        if (response.data.status === 'firstLevelFailed'){
          addToast({
            severity:'error',
            summary:'К сожалению компания не может быть аккредитована',
            detail: 'Свяжитесь с нами для уточнения деталей',
          });
        }
        if (response.data.status === 'firstLevelAccepted'){
          addToast({
            severity:'success',
            summary:'Заявка на аккредитацию отправлена',
            detail: 'Укажите регион, виды работ и услуг',
            life: 3000
          });
        }
        //console.log('ID пользователя:', userID.value);
        getOrganizations(userID.value);
      })
      .catch(error => {
        console.error('Ошибка:', error);
      });
};

const sendOrganization = (organization) => {
  const senddata = {
    id: organization.id,
    region: organization.region,
    selectedNodeKeys: organization.selectedNodeKeys,
    selectedCities: organization.selectedCities

  };
  axios
      .post('/update_organization/', senddata)
      .then(response => {
        if (response.data.status === 'ok'){
          addToast({
            severity:'success',
            summary:'Данные сохранены',
            detail: ' ',
            life: 3000
          });
          store.dispatch('loadUserData');
        }
      })
      .catch(error => {
        console.error('Ошибка:', error);
      });
};


const newOrganization = () => {
  fieldInn.value = true;
  nextTick(() => { inputINN.value.$el.focus(); });
};

const sendINN = () => {
  fieldInn.value = false;
  addOrganization();
  newOrganizationINN.value = '';
};

const getStatusColor = (status) => {
  const colors = {
    'аккредитация отклонена': 'text-red-500',
    'в процессе': 'text-blue-500',
    'успешно пройдена': 'text-green-500'
  };
  return colors[status] || '';
};

const showDetails = (status) => {
  const show = {
    'аккредитация отклонена': false,
    'в процессе': true,
    'успешно пройдена': true
  };
  return show[status] || '';
};

const onSelectionChange = (company) => {
  sendOrganization(company);
  console.log('Выбранные узлы услуг:', company.selectedNodeKeys);
}



//---------------------------------------------------------------------//
onMounted( async () => {

  axios
      .get('/get_services_tree/')
      .then(response => {
        treeData.value = response.data
      })
      .catch(error => {
        console.error('Ошибка:', error);
      });
  getOrganizations(userID.value);

});
//---------------------------------------------------------------------//

const readyINN = computed(() => {
  const realNum = newOrganizationINN.value.replace(/[_\s]/g, "");
  return (realNum.length==10)||(realNum.length==12)
});

//---------------------------------------------------------------------//




</script>




<style scope>
.container{
  overflow-x: hidden !important;
}


@keyframes increaseWidth {
  0% {
    width: 0rem; /* начальная ширина */
  }
  100% {
    width: 18rem; /* конечная ширина */
  }
}

@keyframes decreaseWidth {
  0% {
    width: 18rem; /* начальная ширина */
  }
  100% {
    width: 0rem; /* конечная ширина */
  }

}

.increase {
  animation: increaseWidth 0.4s ease;
  animation-fill-mode: forwards;
}

.decrease {
  animation: decreaseWidth 0.4s ease;
  animation-fill-mode: forwards;
}

.p-tree  {
  border: 0px solid #fff !important;
}
</style>