import  { createRouter, createWebHistory } from 'vue-router';

// подключаем страницы
import frontPage from './views/page-front'
import partnerPage from './views/page-partner'
import customerPage from './views/page-customer'
import catalogPage from './views/page-catalog'
import housePage from './views/page-house'

import testPage from './views/page-test'

//маршруты
const routes = [
    { path: '/',          name: 'frontPage',  component: frontPage },
    { path: '/partner',   name: 'partner',    component: partnerPage },
    { path: '/customer',  name: 'customer',   component: customerPage },
    { path: '/catalog',  name: 'catalog',   component: catalogPage },
    { path: '/house',  name: 'house',   component: housePage },

    { path: '/test',  name: 'test',   component: testPage },
]


const router = createRouter({
    history: createWebHistory(),
    routes: routes
  });
  
  export default router;