<template>
        
    <div class="flex flex-column justify-content-center align-items-center min-h-full text-sm">

        <div class="mb-6 w-9 text-center text-xs">
            <p>Войдите или зарегистрируйтесь, чтобы получить доступ ко всем возможностям</p>

            <p>Вход производится через SMS c кодом доступа</p>
        </div>

        <SelectButton v-model="valueUserType" :options="optionsUserType"  /> 

        <div class="flex flex-row mt-6">
            <span class="p-float-label" style="margin-right: 10px;">
                <InputMask ref="inputPhone" class="text-base" style="width:228px;" id="txt" type="tel" v-model="phone"  mask="+7 (999) 999-9999" @keydown.enter="greet"/>
                <label for="txt">Телефон</label>
            </span> 
            <ButtonItem icon="pi pi-arrow-right" style="color:white; " severity="primary"  @click="greet"  :disabled="notReady" ></ButtonItem>
        </div>

        <div class="mt-6 mb-8 w-9  text-center text-xs">
            Отправляя номер телефона Вы подтверждаете своё согласие с <a href="/privacy" >политикой обработки персональных данных</a>
        </div>        


    </div>

</template>

<script setup>
    //общий 
    import { ref, computed, onMounted } from 'vue';
    import { useStore } from 'vuex';
    //import { useRouter } from 'vue-router';

    import { addToast, requestSMS } from './functions';    

    const store = useStore();
    //const router = useRouter();
    
   
    const phone = ref('');
    const inputPhone = ref();
    const optionsUserType = ref(['Для клиента', 'Для партнёра']);
    const valueUserType = ref('Для клиента');

    const greet = () => {
        if (!notReady.value){
                    addToast({
                        severity:'success', 
                        summary:'Код доступа отправлен на номер:', 
                        detail: phone.value,
                        life: 3000
                    });

                    
                    store.dispatch('setPhone', phone.value);

                    if (valueUserType.value === 'Для клиента\u00A0'){
                        store.dispatch('setIsPartner',false);
                    }else{
                        store.dispatch('setIsPartner',true);
                    }

                    store.commit('SET_SHOW_WAITING', true);
                    requestSMS({phone: phone.value});

                }
    }

//---------------------------------------------------------------------//    

    const notReady = computed(() =>{
        return phone.value.includes('_')||phone.value.length<1;
    });

//---------------------------------------------------------------------//        

//---------------------------------------------------------------------//    


onMounted(() => {
    console.log(inputPhone.value);
    store.commit('SET_SHOW_WAITING', false); 
    setTimeout(() => {
        inputPhone.value.$el.focus();
    }, 400);
        
});
//---------------------------------------------------------------------//

</script>

