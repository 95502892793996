<template>
  <div :class="styles">
    <slot></slot>
  </div>
</template>

<script setup>
import {defineProps, computed} from 'vue'

const props = defineProps({
  inCenter: {type: Boolean},
  flex: {type: Boolean},
  alignItemsStart: {type: Boolean},
  noAlignItems: {type: Boolean},
  justifyContentStart: {type: Boolean},
  noJustifyContent: {type: Boolean},
});

let styles = computed(()=>{
  return {
    ['w-12 md:w-10 lg:w-9 xl:w-8']:props.inCenter,
    'w-full': !props.inCenter,
    'flex': props.flex,
    'align-items-center': !props.noAlignItems && !props.alignItemsStart,
    'align-items-start': !props.noAlignItems && props.alignItemsStart,
    'justify-content-center': !props.noJustifyContent && !props.justifyContentStart,
    'justify-content-start': !props.noJustifyContent && props.justifyContentStart,
  }
})
</script>

<style scoped>

</style>