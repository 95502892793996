<template>
    <div class="flex flex-row justify-content-center align-items-center min-h-full">
        <form @submit="onSubmit" class="flex flex-column gap-2">
            <span class="p-float-label" style="margin-right: 10px; margin-bottom: 40px;">
                <InputText id="name" ref="inputName" v-model="userName" @keydown.enter="greet" />
                <label for="name">Ваше имя *</label>
            </span>
            <span class="p-float-label" style="margin-right: 10px; margin-bottom: 40px;">
                <InputText id="email" v-model="email" @keydown.enter="greet" />
                <label for="email">Электронная почта</label>
            </span>                
            <ButtonItem icon="pi pi-arrow-right" severity="primary"  @click="greet" size="normal" :disabled="notReady" style="color:white; width: 250px;"></ButtonItem>
        </form>
    </div>
</template>

<script setup>
    //общий 
    import { ref, computed, onMounted, nextTick} from "vue";
    import axios from 'axios';
    import { useStore } from "vuex";
    import { useRouter } from 'vue-router';

    import { updateUser, addToast } from './functions';

    const store = useStore();
    const router = useRouter();

    //const phone = ref();
    const userName = ref('');
    const email = ref('');
    const inputName = ref();

    const greet= () => {
                if (!notReady.value){
                    addToast({
                        severity:'success', 
                        summary:'Данные сохранены', 
                        //detail: this.userName
                    });
                    const senddata = {
                        phone: store.getters.getPhone,
                        name: userName.value,
                        email: email.value
                    };
                    updateUser({
                        id: store.getters.getUser.id,
                        phone: store.getters.getPhone,
                        name: userName.value,
                        email: email.value
                    });                    
                    
                    axios
                        .post('/register/', senddata)
                        .then(response => {
                            if (response.data.status === 'ok'){
                                addToast({
                                    severity:'success', 
                                    summary:'Данные сохранены', 
                                    detail: ' ',
                                    life: 3000
                                });
                            
                                if (store.getters.isPartner){
                                    router.push('profile');
                                }else{
                                    router.push('house-form');
                                }                            

                            }
                        })
                        .catch(error => {
                            console.error('Ошибка:', error);
                        });
                }

            }


//---------------------------------------------------------------------//    
    onMounted(() => {
        store.commit('SET_SHOW_WAITING', false); 
        nextTick(() => { inputName.value.$el.focus(); });
    });
//---------------------------------------------------------------------//
    
    const notReady = computed(() => {
        return userName.value.length<2
    });

//---------------------------------------------------------------------//    

</script>

