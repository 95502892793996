<template>
    <div class="w-screen h-screen fixed flex justify-content-center align-items-center" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
       <div class="content">
         Offset X: {{ menuOffset }}
      </div>
    </div>
</template>
  
<script setup>


function findIntersectionPoint(line1Start, line1End, line2Start, line2End) {
  const [x1, y1] = line1Start;
  const [x2, y2] = line1End;
  const [x3, y3] = line2Start;
  const [x4, y4] = line2End;

  const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

  // Проверка на параллельность линий
  if (denominator === 0) {
    return null;
  }

  const intersectionX = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / denominator;
  const intersectionY = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / denominator;

  return [intersectionX, intersectionY];
}

// Пример использования
const line1Start = [0, 20];
const line1End = [200, 20];
const line2Start = [180, 0];
const line2End = [180, 150];

const intersectionPoint = findIntersectionPoint(line1Start, line1End, line2Start, line2End);

if (intersectionPoint) {
  console.log("Точка пересечения:", intersectionPoint);
} else {
  console.log("Линии параллельны, точка пересечения отсутствует.");
}


</script>
  


 
