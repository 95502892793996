<template>
    <div class="flex flex-column justify-content-center align-items-center min-h-full gap-3">

        <div class="text-center h-4rem">
            Мы отправили SMS c кодом на номер <br> {{ phone }} <span class="cursor-pointer text-color-secondary" @click="editPhone"> изменить номер </span> 
        </div>

        <div class="flex flex-row justify-content-center align-items-center min-h-full gap-4">
                
            <InputText ref="inputNum1" id="txt" type="tel"  v-model="code1" class="w-3rem text-center"  @keydown.backspace="deleteLast"/>
            <InputText ref="inputNum2" id="txt" type="tel"  v-model="code2" class="w-3rem text-center"  @keydown.backspace="deleteLast"/>
            <InputText ref="inputNum3" id="txt" type="tel"  v-model="code3" class="w-3rem text-center"  @keydown.backspace="deleteLast"/>
            <InputText ref="inputNum4" id="txt" type="tel"  v-model="code4" class="w-3rem text-center" @keydown.backspace="deleteLast"/>


            <!-- <ButtonItem icon="pi pi-arrow-right" style="color:white" severity="primary"  @click="greet" size="normal" :disabled="notReady"  ></ButtonItem> -->

        </div>

        <div class="text-center mt-5 mb-8">
            <ButtonItem class="bg-secondary w-17rem justify-content-center" :disabled="countingDown" @click="sendSMS">Повторить код ({{ countdownValue  }} сек) </ButtonItem>
        </div>

    </div>        
</template>

<script setup>
    //общий 
    import { ref, computed, onMounted, nextTick, watch } from "vue";
    import axios from 'axios';
    
    import { useStore } from "vuex";
    const store = useStore();
    
    import { useRouter } from 'vue-router';
    const router = useRouter();

    import { addToast, requestSMS } from './functions';

   
    const inputNum1 = ref();
    const inputNum2 = ref();
    const inputNum3 = ref();
    const inputNum4 = ref();

    const code1 = ref('');
    const code2 = ref('');
    const code3 = ref('');
    const code4 = ref('');    
    

    const phone = ref(store.getters.getPhone);

    const code = computed(() => {
        const fullCode = code1.value + code2.value + code3.value + code4.value;
        return fullCode
    });    

    //обратный отсчёт
    const countdownValue = ref();
    const countingDown = ref(false);

    const startCountdown = () => {
        countingDown.value = true;
        countdownValue.value = 120;

        const timer = setInterval(() => {
            countdownValue.value--;

            if (countdownValue.value === 0) {
            clearInterval(timer);
            countingDown.value = false;
            }
        }, 1000);
    };

    //редактирование номера
    const editPhone = () => {
        store.commit('SET_AUTH_STATUS', 'login');
    };

    //повторная отправка SMS
    const sendSMS = () => {
        requestSMS({phone: phone.value});
        code1.value = code2.value = code3.value = code4.value = '';
        startCountdown();
        nextTick(() => { inputNum1.value.$el.focus(); });
    };

    watch (code, (newValue, oldValue) => {
        console.log('Значение code:', newValue);
        console.log('Предыдущее значение code:', oldValue);
        if (newValue.length == 0) {
                nextTick(() => { inputNum1.value.$el.focus(); });
        }        
        if (newValue.length == 1) {
                nextTick(() => { inputNum2.value.$el.focus(); });
        }
        if (newValue.length == 2) {
                nextTick(() => { inputNum3.value.$el.focus(); });
        }
        if (newValue.length == 3) {
                nextTick(() => { inputNum4.value.$el.focus(); });
        }
        if (newValue.length == 4) {
            greet();
        }                              
    });
    
    const deleteLast = () => {
        
        if (code.value.length == 3  ) {
            code3.value = '';
        }else{
            if (code.value.length == 2  ) {
                code2.value = '';
            }else{
                if (code.value.length == 1  ) {
                    code1.value = '';
                } 
            }  
        }
          

    }

    const greet = () => {
                if (!notReady.value){
                    
                    store.commit('SET_SHOW_WAITING', true); 
                    const senddata = {
                        phone: store.getters.getPhone,
                        code: code.value
                    };
              
                    axios
                        .post('/checkcode/', senddata)
                        .then(response => {
                        
                            console.log('Статус', response.status);
                            console.log('Статус API:', response.data.status);
                            //console.log('Токен:', response.data.token);
                            console.log('isAuth:', response.data.isAuth);
                        
                            if (response.data.status === 'ok'){
                                
                                addToast({
                                    severity:'success', 
                                    summary:'Код верный', 
                                    detail: 'Вы авторизованы',
                                    life: 3000
                                });

                                
                                store.dispatch('setAuth',true);

                                store
                                    .dispatch('loadUserData')
                                    .then(() => {
                                        if (store.getters.isPartner){
                                            router.push({name: 'partner'});
                                            store.commit('SET_SHOW_MODAL', false);
                                        }else{
                                            router.push({name: 'customer'});
                                            store.commit('SET_SHOW_MODAL', false);
                                        } 
                                    })
                                    .catch ((error) => {
                                        console.error ('Ошибка загрузки пользователя:', error);
                                    });

                            }
                            
                            if (response.data.status === 'wrongCode'){
                                addToast({
                                    severity:'error', 
                                    summary:'Неверный код', 
                                    detail: 'Попробуйте ещё раз',
                                    life: 5000
                                });
                                code1.value = '';
                                code2.value = '';
                                code3.value = '';
                                code4.value = '';
                                nextTick(() => { inputNum1.value.$el.focus(); });
                                store.commit('SET_SHOW_WAITING', false); 
                            }
                            
                            if (response.data.status === 'new'){
                                addToast({
                                    severity:'info', 
                                    summary:'Код верный', 
                                    detail: 'Зарегистрируйтесь',
                                    life: 3000
                                });
                                store.commit('SET_AUTH_STATUS', 'register');
                            }

                        
                        })
                        .catch(error => {
                            console.error('Ошибка:', error);
                        });
                }

            };


//---------------------------------------------------------------------//    
    onMounted(() => {
        store.commit('SET_SHOW_WAITING', false); 
        startCountdown();
        nextTick(() => { inputNum1.value.$el.focus(); });
        const phone = ref(store.getters.getPhone);
        console.log(phone.value);
    });
//---------------------------------------------------------------------//

    const notReady = computed(() => {
        return code.value.length<4
    });

//---------------------------------------------------------------------//    

</script>



<style scoped>
    .no-spinner {
        -webkit-appearance: none;
        appearance: none;
    }
    
  
</style>