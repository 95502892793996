<template>
  <div class="w-full">
    <div class="flex flex-wrap">
      <label class="w-full text-center p-1">{{props.label}}</label>
      <input-number class="w-12 text-right-ch-input input-width-fix"
            locale="ru"
            :allowEmpty="false"
            :min="props.min"
            :max="props.max"
            v-model="model"
            inputId="integeronly"
        />
    </div>

    <slider-item 
        :min="props.min" 
        :max="props.max" 
        v-model="model"
        :step="props.step"
        ariaLable="Куку"
        class="-mt-1"
    />
  </div>
</template>

<script setup>

import {defineProps, defineModel} from 'vue'



const props = defineProps({
  min: { type: Number, required: true },
  max: { type: Number, required: true },
  step: { type: Number, required: true },
  label: { type: String, required: true }
});

const model = defineModel();
//const model = ref(15000000);


</script>

<style scoped>

</style>