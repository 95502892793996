<template>
    <div class="fixed top-0 right-0 w-full lg:w-4 h-full z-5 overflow-x-hidden pointer-events-none" >
        <Transition name="modal" >
            <div v-if="showModal" ref="slideOver" class="surface-overlay top-0 right-0 absolute shadow-2 w-full lg:w-11 h-full z-5 pointer-events-auto">
                <div class="flex flex-column h-full p-4">

                    <div class="flex align-items-center justify-content-between mb-4">
                        <span class="text-900 text-xl font-medium title">{{ modalName }}</span>
                        <ButtonItem icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" @click="store.commit('SET_SHOW_MODAL', false)"></ButtonItem>
                    </div>
                    <div class="border-1 border-dashed surface-border flex-auto overflow-y-auto p-3" :class="[showWaiting ? 'opacity-40' : 'opacity-100']">
                            <viewLogin v-if = "authStatus === 'login'"></viewLogin>
                            <viewSMS v-if = "authStatus === 'sms'"></viewSMS>
                            <viewRegister v-if = "authStatus === 'register'"></viewRegister>
                    </div>

                </div>
            </div>
        </Transition>
    </div>
    <Transition name="blur" >
        <div v-if="showModal" class="fixed top-0 right-0 w-full h-full z-4 overflow-x-hidden" @click="store.commit('SET_SHOW_MODAL', false)" style="backdrop-filter: blur(3px);"></div>
    </Transition>

</template>

<script setup>
    import { defineProps, computed, ref } from 'vue'
    import { useStore } from "vuex";

    const props = defineProps(['modalName']);
    console.log(props.modalName);

    const store = useStore();
    const showModal = computed(() =>{
        return store.state.showModal;
    });
    
    const slideOver = ref(null);
    
    const authStatus = computed(() =>{
        return store.state.authStatus;
    });

    const showWaiting = computed(() =>{
        return store.state.showWaiting;
    });     

</script>

<style scoped>
    .modal-enter-active {
        animation: fadeinright 0.35s ease;
    }
    .modal-leave-active {
        animation: fadeoutright 0.35s ease;
    }

    .blur-enter-active {
        animation: fadein 0.35s ease;
    }
    .blur-leave-active {
        animation: fadeout 0.35s ease;
    }    
  
</style>