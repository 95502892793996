<template>
  <main-page>
    <container-block in-center class="mt-5">
      <!-- начало свайпера -->
      <sidebar-item v-model:visible="fullScreenMainSwiperShown" header="&nbsp;" position="full" class="fullScreenSwiper" @click="closeFullScreenMainSwiper">
        <div class="border-round w-full shadow-3" style="aspect-ratio: 21/9;">
          <swiper-container
              :slides-per-view="1"
              :loop="true"
              :space-between="10"
              :navigation="true"
              :speed="800"
              :zoom="true">
            <swiper-slide v-for="photo in photosForPreview" :key="photo.big">
              <div class="swiper-zoom-container">
                <img :src="photo.big" class="w-12" style="object-fit: cover; height:auto; aspect-ratio: 21/9;"/>
              </div>
            </swiper-slide>
          </swiper-container>
        </div>
      </sidebar-item>
      <div class="border-round w-full shadow-3 overflow-hidden" style="aspect-ratio: 21/9;">
        <swiper-container
            :slides-per-view="1"
            :loop="true"
            :space-between="10"
            :navigation="true"
            :speed="800"
            thumbs-swiper=".mySwiper2"
        @click="openfullScreenMainSwiper">
          <swiper-slide v-for="photo in photosForPreview" :key="photo.big" class="">
            <div class="swiper-zoom-container">
              <img :src="photo.big" class="w-12" style="object-fit: cover; height:auto; aspect-ratio: 21/9;"/>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
      <div class="border-round w-full shadow-2 overflow-hidden surface-300 mt-1" style="aspect-ratio: 21/1.5;">
        <swiper-container class="mySwiper2" :loop="true" :space-between="5" :slides-per-view="slidesOnPreview"
                          :free-mode="true"
                          :watch-slides-progress="true" :loopAddBlankSlides="true"
        >
          <swiper-slide v-for="photo in photosForPreview" :key="photo.big" class="">

            <img :src="photo.big" class="w-12" style="object-fit: cover; height:auto; aspect-ratio: 21/9;"/>

          </swiper-slide>

        </swiper-container>
      </div>
      <!-- конец свайпера -->
    </container-block>
    <container-block in-center no-justify-content class="mt-5" style="display: grid; gap: 1rem; grid-template-columns: 100%;">
      <div class="main-first">
        <div class="flex flex-wrap justify-content-between align-items-center px-4 xl:px-5 border-round surface-border border-1 bg-white shadow-1 h-6rem">
          <span class="title text-2xl">Микея 5</span>
          <span class="text-lg">205 кв.м</span>
          <span class="flex align-items-center text-lg"><i class="pi pi-th-large mr-2 text-primary text-xl" v-tooltip.top="'Материал стен'"></i>Газобетонные блоки</span>
          <span class="flex align-items-center text-lg"><i
              class="material-symbols-outlined text-primary font-light text-4xl  mr-1"  v-tooltip.top="'Количество спален'">bed</i> 3</span>
          <span class="flex align-items-center text-lg"><i
              class="material-symbols-outlined  text-primary font-light text-4xl  mr-1"  v-tooltip.top="'Количество санузлов'">shower</i> 2</span>
        </div>
        <div class="flex justify-content-between align-items-center px-4 xl:px-5 border-round surface-border border-1 bg-white shadow-1 h-6rem ">
          <span class="text-2xl title" style="text-wrap: nowrap;">12 568 000 ₽</span>
          <span class="text-md text-center">В ипотеку от <span class="text-primary font-semibold" style="text-wrap: nowrap;">23 337 ₽/мес.</span></span>
        </div>
      </div>

      <div class="main-second">
        <!-- начало характеристик -->
        <div class="flex flex-column justify-content-center align-items-center px-4 xl:px-6 border-round surface-border border-1 bg-white shadow-1 text-base">
          <span class="title text-2xl mt-5 text-left w-full">Характеристики</span>
          <div class="w-12 flex justify-content-between mt-4">
            <span class="">Общая площадь дома</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span class="">205 кв.м</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span class="">Жилая площадь</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span class="">134 кв.м</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span :class="{'params1': isMobile}" class="">Тип фундамента</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span :class="{'params2': isMobile}" class="">Монолитная плита</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span :class="{'params1': isMobile}" class="">Материал стен</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span :class="{'params2': isMobile}" class="">Газобетонные блоки</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span :class="{'params1': isMobile}">Тип отделки фасада</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span :class="{'params2': isMobile}">Фасадная штукатурка</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span :class="{'params1': isMobile}" class="">Материал перегородок</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span :class="{'params2': isMobile}" class="">Кирпичные</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span :class="{'params1': isMobile}" class="">Материал перекрытий</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span :class="{'params2': isMobile}" class="">Стальные балки</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span :class="{'params1': isMobile}" class="">Материал кровли</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span :class="{'params2': isMobile}" class="">Металлическая фальцевая</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span class="">Камин</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span class="">Да</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span class="">Гараж</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span class="">Нет</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span class="">Чердак</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span class="">Да</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4">
            <span class="">Подвал</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span class="">Нет</span>
          </div>
          <div class="w-12 flex justify-content-between mt-4 mb-6">
            <span class="">Терраса</span>
            <span class="text-xs border-none border-bottom-2 border-dashed border-200 flex-grow-1">.</span>
            <span class="">Да</span>
          </div>

        </div>
        <!-- конец характеристик -->
        <!-- начало планировки -->
        <div class="flex justify-content-between border-round surface-border border-1 bg-white shadow-1 xl:p-3">
          <sidebar-item v-model:visible="fullScreenLayoutSwiperShown" header="&nbsp;" position="full" class="fullScreenSwiper" @click="closeFullScreenLayoutSwiper">
            <div class="border-round w-full shadow-3" style="aspect-ratio: 21/9;">
              <swiper-container
                  :slides-per-view="1"
                  :loop="true"
                  :space-between="10"
                  :navigation="true"
                  :zoom="true">
                <swiper-slide>
                  <div class="swiper-zoom-container">
                    <img src="/images/test/houses/mikea_plan_01.jpg" style="width: auto; max-width: 100%;"/>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="swiper-zoom-container">
                    <img src="/images/test/houses/mikea_plan_01.jpg" style="width: auto; max-width: 100%;"/>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="swiper-zoom-container">
                    <img src="/images/test/houses/mikea_plan_01.jpg" style="width: auto; max-width: 100%;"/>
                  </div>
                </swiper-slide>
              </swiper-container>
            </div>
          </sidebar-item>
          <TabView :scrollable="true" class="text-base" :pt="{nav: ({class:['title', 'text-lg']})}" style="width: 100%;">
            <TabPanel header="Первый этаж">
              <img @click="openFullScreenSwiper" src="/images/test/houses/mikea_plan_01.jpg" class="h-auto mt-4" style="width: 100%;"/>
            </TabPanel>
            <TabPanel header="Второй этаж">
              <img @click="openFullScreenSwiper"  src="/images/test/houses/mikea_plan_01.jpg" class="h-auto mt-4" style="width: 100%;" />
            </TabPanel>
            <TabPanel header="Третий этаж">
              <img @click="openFullScreenSwiper"  src="/images/test/houses/mikea_plan_01.jpg" class="h-auto mt-4" style="width: 100%;" />
            </TabPanel>
          </TabView>
        </div>
        <!-- конец планировки -->
      </div>

      <!-- начало технологий -->
      <div class="flex justify-content-between align-items-center xl:px-6 border-round surface-border border-1 bg-white shadow-1">
        <TabView :scrollable="true" class="text-base w-full" :pt="{nav: ({class:['title', 'text-lg']})}"> <!--  -->
          <TabPanel header="Фундамент">
            <div class="flex justify-content-between flex-wrap w-full">
              <div class="flex flex-column xl:w-6">
                <span class="text-xl font-semibold my-3">Монолитная плита</span>
                <span class="m-0">

                                    Этот вид фундамента представляет собой крупную плиту под всей площадью дома, расположенную на подушке из песка и гравия.<br>
                                    Он обеспечивает равномерное распределение нагрузок на грунт, обеспечивая тем самым устойчивость и надежность всей
                                    конструкции. Кроме того, благодаря монолитной плите можно избежать усадки и деформации здания, что важно для его целостности.
                                    <br><br>
                                    <span class="font-semibold">Основные преимущества</span><br>
                                    <ul>
                                        <li>хорошо переносит подвижки грунта, что предотвращает появление трещин и зазоров в стенах</li>
                                        <li>несложная технология возведения</li>
                                        <li>подходит для сейсмоактивных зон</li>
                                        <li>подходит для почв с большой глубиной промерзания</li>
                                    </ul>
                                </span>

              </div>
              <div class="xl:w-4">
                <img src="/images/test/tech/basement.jpg" class="w-full h-auto mt-5"/>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Материал стен">
            <div class="flex justify-content-between flex-wrap w-full">
              <div class="flex flex-column xl:w-6">
                <span class="text-xl font-semibold my-3">Кирпич</span>
                <span class="m-0">
                                    Стены из кирпича - традиционны для средней полосы России. Высокая прочность и хорошая теплоизоляция,
                                    а также обладают высокой износостойкостью, прочностью и пожаростойкостью.
                                    Они также долговечны и могут прослужить более 100 лет.
                                </span>

              </div>
              <div class="xl:w-4">
                <img src="/images/test/tech/bricks2.jpg" class="w-full h-auto mt-5"/>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Фасад">
            <div class="flex justify-content-between flex-wrap w-full">
              <div class="flex flex-column xl:w-6">
                <span class="text-xl font-semibold my-3">Штукатурка</span>
                <span class="m-0">

                                    Фасады, отделанные штукатуркой, имеют несколько преимуществ.
                                    Во-первых, штукатурка придает фасаду эстетичный внешний вид и позволяет создать различные текстуры и узоры,
                                    что делает дом более привлекательным. Кроме того, штукатурка защищает стены от воздействия атмосферных явлений,
                                    таких как дождь, снег, ветер, и улучшает теплоизоляционные и влагоустойчивые свойства стен. Это увеличивает срок службы
                                    и уменьшает необходимость в ремонте
                                </span>

              </div>
              <div class="xl:w-4">
                <img src="/images/test/tech/wall_cover.jpg" class="w-full h-auto mt-5"/>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Перекрытия">
            <div class="flex justify-content-between flex-wrap w-full">
              <div class="flex flex-column xl:w-6">
                <span class="text-xl font-semibold my-3">Стальные балки</span>
                <span class="m-0">

                                    Стальные балки обеспечивают высокую прочность и надежность конструкции, равномерно распределяя
                                    нагрузки и обеспечивая стабильность здания. Благодаря использованию стальных балок можно избежать
                                    деформации и снижения прочности перекрытий на протяжении долгого времени. Еще одним преимуществом
                                    является универсальность в применении: стальные балки могут быть адаптированы к различным
                                    архитектурным проектам и инженерным решениям. Также их использование способствует сокращению времени
                                    строительства и экономии ресурсов.

                                </span>

              </div>
              <div class="xl:w-4">
                <img src="/images/test/tech/steel_beam.jpg" class="w-full h-auto mt-5"/>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Кровля">
            <div class="flex justify-content-between flex-wrap w-full">
              <div class="flex flex-column xl:w-6">
                <span class="text-xl font-semibold my-3">Стальная фальцевая кровля</span>
                <span class="m-0">

                                    Стальная фальцевая кровля - это надежное и долговечное решение для вашего дома. Ее высокая прочность
                                    и долговечность гарантируют долгий срок службы, особенно при использовании качественных материалов
                                    и правильном монтаже. Кроме того, фальцевая система обеспечивает надежную защиту от осадков,
                                    предотвращая скапливание снега и воды. Благодаря высокой тепло- и гидроизоляции, стальная фальцевая
                                    кровля также обеспечивает надежную защиту от внешних воздействий, делая ваш дом комфортным и
                                    безопасным.

                                </span>

              </div>
              <div class="xl:w-4">
                <img src="/images/test/tech/roof.jpg" class="w-full h-auto mt-5"/>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
      <!-- конец технологий -->
    </container-block>
    <container-block in-center flex justify-content-start no-align-items
                     class="mt-4 px:4 xl:px-6 py-5 border-round surface-border border-1 bg-white shadow-1 p-4 flex-wrap align-items-end">
      <span class="title text-2xl text-left w-12">Ипотечные программы</span>
      <div class="w-full my-4">
        <div
            class="mt-2 surface-card border-2 px-4 py-2 border-round flex flex-column md:flex-row align-items-start md:align-items-center md:justify-content-between cursor-pointer"
            @click="mortgageProgram = 0"
            :class="{ 'surface-border': mortgageProgram !== 0, 'border-primary': mortgageProgram === 0 }">
          <div class="flex align-items-start mr-0 md:mr-8 ">
            <i class="material-symbols-outlined text-xxl mr-3"
               :class="{ 'text-primary-600': mortgageProgram === 0, 'text-color-secondary': mortgageProgram !== 0 }">assured_workload</i>
            <div>
              <div class="font-semibold mb-2" :class="{ 'text-primary-600': mortgageProgram === 0 }">Господдержка
              </div>
              <div class="flex flex-grow gap-2 text-xs">
                Без требований к семейному положению и наличию детей
              </div>
            </div>
          </div>
          <div class="ml-0 md:ml-8 mt-2 md:mt-0 border-1 p-1 px-2 border-round flex align-items-center font-medium"
               :class="{ 'surface-100 text-600 surface-border': mortgageProgram !== 0, 'bg-white border-primary': mortgageProgram === 0 }">
            <span>от 23 337 ₽ / мес.</span>
          </div>
        </div>
        <div
            class="mt-2 surface-card border-2 px-4 py-2 border-round flex flex-column md:flex-row align-items-start md:align-items-center md:justify-content-between cursor-pointer"
            @click="mortgageProgram = 1"
            :class="{ 'surface-border': mortgageProgram !== 1, 'border-primary': mortgageProgram === 1 }">
          <div class="flex align-items-start mr-0 md:mr-8">
            <i class="material-symbols-outlined text-xxl mr-3"
               :class="{ 'text-primary-600': mortgageProgram === 1, 'text-color-secondary': mortgageProgram !== 1 }">family_restroom</i>
            <div>
              <div class="font-semibold mb-2" :class="{ 'text-primary-600': mortgageProgram === 1 }">Семейная</div>
              <div class="flex flex-grow gap-2 text-xs">
                Для семей с детьми
              </div>
            </div>
          </div>
          <div class="ml-0 md:ml-8 mt-2 md:mt-0 border-1 p-1 px-2 border-round flex align-items-center font-medium"
               :class="{ 'surface-100 text-600 surface-border': mortgageProgram !== 1, 'bg-white border-primary': mortgageProgram === 1 }">
            <span>от 14 809 ₽ / мес.</span>
          </div>
        </div>
        <div
            class="mt-2 surface-card border-2 px-4 py-2 border-round flex flex-column md:flex-row align-items-start md:align-items-center md:justify-content-between cursor-pointer"
            @click="mortgageProgram = 2"
            :class="{ 'surface-border': mortgageProgram !== 2, 'border-primary': mortgageProgram === 2 }">
          <div class="flex align-items-start mr-0 md:mr-8">
            <i class="material-symbols-outlined text-xxl mr-3"
               :class="{ 'text-primary-600': mortgageProgram === 2, 'text-color-secondary': mortgageProgram !== 2 }">table</i>
            <div>
              <div class="font-semibold mb-2" :class="{ 'text-primary-600': mortgageProgram === 2 }">Базовая на
                строительство дома
              </div>
              <div class="flex flex-grow gap-2 text-xs">
                По программе можно взять ипотеку на земельный участок и строительство жилого дома
              </div>
            </div>
          </div>
          <div class="ml-0 md:ml-8 mt-2 md:mt-0 border-1 p-1 px-2 border-round flex align-items-center font-medium"
               :class="{ 'surface-100 text-600 surface-border': mortgageProgram !== 2, 'bg-white border-primary': mortgageProgram == 2 }">
            <span>от 35 033 ₽ / мес.</span>
          </div>
        </div>
      </div>
      <ViewFilterSlider
          class="py-2 w-12"
          label="Стоимость проекта, ₽"
          :step="1000"
          :max="defaultFilterValues.priceMax"
          :min="defaultFilterValues.priceMin"
          v-model="crip.price">
      </ViewFilterSlider>
      <ViewFilterSlider
          class="py-2 pr-2 w-6"
          label="Первоначальный взнос, ₽"
          :step="1000"
          :max="crip.price"
          :min="defaultFilterValues.priceMin"
          v-model="crip.start">
      </ViewFilterSlider>
      <ViewFilterSlider
          class="py-2 pl-2 w-6"
          label="Срок кредита"
          :step="1"
          :max="30"
          :min="1"
          v-model="crip.time">
      </ViewFilterSlider>
      <div class="flex flex-wrap mt-2 w-12 text-lg text-left">
        <div class=" w-6 mt-5">
          <span class="text-sm text-600">Cтоимость кредита: </span>
          <span class="text-primary font-medium">17,9% - 20,9%</span>
        </div>
        <div class=" w-6 mt-5">
          <span class="text-sm">Ставка: </span>
          <span class="text-primary font-medium">17,2%</span>
        </div>
        <div class=" w-6 mt-5">
          <span class="text-sm">Сумма кредита: </span>
          <span class="text-primary font-medium">{{ creditSum }} ₽</span>
        </div>
        <div class=" w-6 mt-5">
          <span class="text-sm">Ежемесячный платёж: </span>
          <span class="text-primary font-medium">36 560 ₽</span>
        </div>
        <div class="w-full xl:w-6 mt-5">
          <span class="text-sm">Расчёт условий не является публичной офертой. Финальные условия кредитования определяются при заключении договора</span>
        </div>
        <div class="w-full xl:w-6 mt-5 flex justify-content-end">
          <ButtonItem class="w-12" label="Подать заявку"></ButtonItem>
        </div>
      </div>
    </container-block>
    <faq-view/>
  </main-page>
</template>

<script setup>

import {computed, reactive, ref} from 'vue'

import {register} from 'swiper/element/bundle'; //Swiper custom elements
//начало рудимента
import ViewFilterSlider from './view-filter-slider.vue'
import MainPage from "@/components/mainPage.vue";
import ContainerBlock from "@/components/containerBlock.vue";

register();

const fullScreenMainSwiperShown = ref(false)
const fullScreenLayoutSwiperShown = ref(false)

const openFullScreenSwiper = ()=>{
  //if(!isMobile.value){
  fullScreenLayoutSwiperShown.value = true
  //}
}

const closeFullScreenMainSwiper = (e) => {
  if (e.target.classList.contains("swiper-zoom-container") || e.target.classList.contains("p-sidebar-header")){
    fullScreenMainSwiperShown.value = false
  }
}

const openfullScreenMainSwiper = (e)=>{
  console.log(e)
  if(e.target.tagName === "IMG"){
    fullScreenMainSwiperShown.value = true
  }
}

const closeFullScreenLayoutSwiper = (e) => {
  if (e.target.classList.contains("swiper-zoom-container") || e.target.classList.contains("p-sidebar-header")){
    fullScreenLayoutSwiperShown.value = false
  }
}

const mortgageProgram = ref(0)

/*     import jsonData from '../test_data/houses.json'; //данные по домикам из файла
    const houses = ref(jsonData); */

const photos = ref([
  {big: '/images/test/houses/mikea_01.jpg', small: '/images/test/houses/mikea_01_small.jpg'},
  {big: '/images/test/houses/mikea_02.jpg', small: '/images/test/houses/mikea_02_small.jpg'},
  {big: '/images/test/houses/mikea_03.jpg', small: '/images/test/houses/mikea_03_small.jpg'},
  {big: '/images/test/houses/mikea_04.jpg', small: '/images/test/houses/mikea_04_small.jpg'},
]);

let photosForPreview = [...photos.value];
const slidesOnPreview = 5;
if (photos.value.length < slidesOnPreview) {
  for (let i = 0; i < slidesOnPreview - photos.value.length; i++) {
    photosForPreview.push(photos.value[i])
  }
}

const creditSum = computed(() => {
  const result = crip.price - crip.start;
  return result.toLocaleString();
});

const defaultFilterValues = {
  priceMin: 300000,
  priceMax: 30000000,
  areaMin: 10,
  areaMax: 1000,
  floorsMin: 1,
  floorsMax: 3
}

const crip = reactive({
  price: 12564000,
  start: 6000000,
  time: 10,
  selectedStyles: [],
  fireplace: false
});
//конец рудимента

const isMobile = ref(document.body.clientWidth < 1400)

addEventListener("resize", () => {
  setTimeout(() => {
    isMobile.value = document.body.clientWidth < 1400;
  }, 100)
});

</script>

<style>

:root {
  --swiper-scrollbar-drag-bg-color: var(--primary-color) !important;
  --swiper-navigation-color: var(--primary-color) !important;

}

swiper-container::part(bullet-active) {
  background-color: var(--primary-color);
}

swiper-container::part(wrapper) {
  overflow: visible;
}

swiper-container::part(scrollbar) {
  margin-bottom: -4px;
  height: 2px;
}

swiper-container::part(button-prev) {
  width: 35px;
  height: 35px;
}

swiper-container::part(button-next) {
  width: 35px;
  height: 35px;
}

.params1 {
  display: flex;
  align-items: end;
  max-width: 45%;
  width: min-content;
}

.params2 {
  display: flex;
  align-items: end;
  text-align: end;
  width: min-content;
}

.fullScreenSwiper {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.fullScreenSwiper .p-sidebar-content {
  display: flex;
  align-items: center;
}

.fullScreenSwiper .p-sidebar-content {
  padding: 0;
}

.fullScreenSwiper .swiper-zoom-container {
  height: 90vh;
}

.main-desc {
  grid-area: main-desc;
}

.main-price {
  grid-area: main-price;
}

.characteristics {
  grid-area: characteristics;
}

.layouts {
  grid-area: layouts;
}

.technologies {
  grid-area: technologies;
}

.main-first {
  display: grid;
  gap: 1rem;
  grid-template-columns: 5fr 4fr;
}

@media screen and (max-width: 1680px) {
  .main-first {
    grid-template-columns: 1fr;
  }
}

.main-second {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1680px) {
  .main-second {
    grid-template-columns: 100%;
  }
}

</style>

