<template>
    
    <div class="surface-card shadow-2 border-round mb-3 mt-3 w-20rem">
                <div class="relative border-round w-full">
                    <img 
                        @click="router.push({name: 'house'})" 
                        class="static border-round border-noround-bottom w-full h-15rem cursor-pointer" 
                        style="object-fit: cover;" 
                        :src="props.photos.small"
                    />
                    <div class="absolute flex justify-content-center align-items-center top-0 right-0 mt-3 mr-3 surface-ground border-circle w-2rem h-2rem hover:bg-primary">
                        <i class="pi pi-heart text-lg mt-1"></i>
                    </div>
                    <div class="absolute flex justify-content-center align-items-center top-0 left-0 mt-3 ml-3 surface-ground border-circle w-2rem h-2rem hover:bg-primary">
                        <i class="pi pi-chart-bar text-lg mt-1"></i>
                    </div>
                </div>
                <div class="p-3" style="min-height: 12rem">
                    <div class="flex justify-content-between">
                        <span class="text-lg font-medium ">{{ props.name }}</span> 
                        <span class="text-lg">{{ props.area }} кв.м</span>
                    </div>
                    <div class="flex justify-content-between mt-3 ">
                        <span class="flex align-items-center"><i class="pi pi-th-large mr-2" ></i>{{ props.material }}</span> 
                        <span class="flex align-items-center"><i class="material-symbols-outlined text-color-secondary text-xxl mr-1">bed</i> {{ props.bedrooms }}</span> 
                        <span class="flex align-items-center"><i class="material-symbols-outlined text-color-secondary text-xxl mr-1">shower</i> {{ props.bathrooms }}</span>  
                                               
                    </div>
                    <div class="flex justify-content-between mt-3">
                        <span class="">Ипотека от {{ props.mortgage.toLocaleString().replace(",", " ") }} ₽/мес.</span>
                    </div>
                    <div class="flex justify-content-between align-items-center mt-4">
                        <span class="font-medium text-lg">{{ (props.price/1000000).toFixed(1) }} млн. ₽</span>
                        <ButtonItem @click="router.push({name: 'house'})" class="w-6" icon="pi pi-cart-plus" label="Заказать"></ButtonItem>                                        
                    </div>
                </div>
            </div>

</template>

<script setup>

    import { defineProps } from 'vue'
    const props = defineProps(['name', 'area', 'material', 'bedrooms','bathrooms', 'mortgage', 'price', 'photos', 'mortgage']);

    import { useRouter } from 'vue-router';
    const router = useRouter();

</script>